import React, { useState, useRef, useEffect } from "react";
import { IconButton, TextField } from "@mui/material";
import { useStore, useSelector } from "react-redux";
import useToasts from "../../../../../../components/CustomToast/useToasts";
import "./index.scss";
import { useLocation } from "react-router-dom";
import handleReadContentVersion from "../../../../services/helpers/handleReadContentVersion";
import editXmlAlias from "../../../../services/editXmlAlias";
import handleEditContentVersion from "../../../../services/helpers/handleEditContentVersion";
import deleteVersion from "../../../../services/deleteVersion";
import CustomModal from "../../../../../../components/CustomModal";
import Dropdown from "../../../../../../components/Dropdown";
import HandleEditMode from "../../../CompareFiles/services/handleEditMode";
import transformForXml from "../../../../services/helpers/transformForXml";
import handlePublish from "../../../AppBar/services/handlePublish";
import Modal from "../../../../../../components/CustomModal";
import CustomInput from "../../../../../../components/CustomInput";
import Icon from "../../../../../../components/CustomIcon/Icon";
import { useTheme } from "../../../../../../theme/themeContext";
import ExportRevisionItem from "../Services/exportRevisionitem";
import RevisionToCompare from "../Services/revisionTCompare";
import getFileContent from "../../../../services/getFileContent";
import getFileXmlContent from "../../../CompareFiles/services/getFileXmlContent";
import handleClickedItem from "../../../../services/helpers/handleClickedItem";
import ClearIcon from "@mui/icons-material/Clear";

const RevisionItem = ({
  data,
  selectedData = {},
  revision,
  setRevision = () => {},
  handleTransform,
  setIsTransformed = () => {},
  compareXML,
  setCompareXML = () => {},
  enableBackground,
  setEnableBackground = () => {},
  setBorder = () => {},
}) => {
  let store = useStore();
  let location = useLocation();
  const { showRight, leftFileName, showLeft } = useSelector((state) => ({
    showRight: state?.entities?.compareFiles?.rightCode?.active,
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    showLeft: state?.entities?.compareFiles?.leftCode?.active,
  }));
  const { addToast } = useToasts();
  const buttonRef = useRef(null);

  const [newFileName, setNewFileName] = useState(data?.xmlAlias);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { themeMode, themeColors } = useTheme();
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);

  const draftPresence = store.getState()?.entities?.explorer?.draftDisplay;
  let selected = useSelector((state) =>
    state?.entities?.revision?.itemSelected
      ? state?.entities?.revision.itemSelected
      : {}
  );

  const { dataVersion, edit, typeFile, readOnly } = useSelector((state) => ({
    dataVersion: state?.entities?.explorer?.skeleton || {},
    edit: state?.entities?.editor || {},
    typeFile: state?.entities?.revision?.revisionSelected?.typeFile,
    readOnly: state?.entities?.explorer?.readOnly,
  }));

  const deleteConfirmation = {
    confirmQuestion: "Are you sure you want to delete this version?",
    versionName: ` ${data?.xmlAlias ?? "My Alias"}`,
    versionDate: data?.release,
  };
  useEffect(() => {
    setNewFileName(selectedData?.xmlAlias);
  }, [selectedData]);
  const handleEditVersion = async () => {
    try {
      store.dispatch({
        type: "revision/setSelectedRevision",
        payload: {
          data: selectedData,
        },
      });
      setShowEditPopup(false);
      await handleEditContentVersion(
        addToast,
        store,
        selectedData?.filename,
        selectedData?.maxOrder,
        selectedData
      );
      setEnableBackground(true);
      if (setRevision && handleTransform) {
        setRevision(false);
        setIsTransformed(false);
      }
      store.dispatch({
        type: "editor/updateXMLState",
        payload: { XMLChanged: false },
      });
      if (showRight) {
        store.dispatch({
          type: "compareFiles/updateCompareState",
          payload: {
            compare: true,
          },
        });
      }
      HandleEditMode(
        store,
        showLeft,
        showRight,
        leftFileName,
        isOn,
        iconClicked
      );
    } catch (error) {
      console.error("Error during publish or edit version process:", error);
      addToast("An error occurred during the process.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handleDiscard = async () => {
    await handleClickedItem(
      addToast,
      store,
      {},
      selectedData?.filename,
      false,
      true
    );
    setEnableBackground(false);
    setShowDiscardPopup(false);
    HandleEditMode(store, showLeft, showRight, leftFileName, isOn, iconClicked);
  };

  const handleReadContent = async () => {
    handleReadContentVersion(addToast, store, data?.filename, data, readOnly);
    store.dispatch({
      type: "deviceview/navigateToHome",
    });
    store.dispatch({
      type: "deviceview/setCurrentPage",
      payload: {
        currentPage: 0,
      },
    });
    setBorder(false);
  };

  const handleEditAlias = async () => {
    try {
      setShowRenamePopup(false);
      await editXmlAlias(
        store,
        data?.filename,
        newFileName,
        addToast,
        selectedData,
        selected
      );
    } catch (error) {
      addToast("Server is unreachable!", "error");
    }
  };

  const handleDelete = async () => {
    setShowDeletePopup(false);
    await deleteVersion(
      store,
      data.filename,
      addToast,
      typeFile,
      selectedData,
      setBorder
    );
  };
  const ExportFile = async (item) => {
    const file = item.filename;

    if (file && file !== "") {
      await ExportRevisionItem(file, addToast, location, item.xmlAlias);
    }
  };
  const compareRevision = async (item) => {
    const file = item.filename;
    const finalXML = await getFileXmlContent(file);
    if (!showLeft && !compareXML) {
      setCompareXML(!compareXML);
      RevisionToCompare(store, item, addToast, finalXML, showLeft);
    } else {
      RevisionToCompare(store, item, addToast, finalXML, showLeft);
    }

    if (showLeft && revision) {
      setRevision(false);
      setIsTransformed(false);
    }
  };

  async function changeHandler(selectedFile) {
    store.dispatch({
      type: "revision/setSelectedItem",
      payload: {
        obj: {},
      },
    });
    store.dispatch({
      type: "revision/setSelectedRevision",
      payload: { data: {} },
    });

    try {
      const file = selectedFile.filename;
      const res = await getFileContent(store, file, readOnly, addToast);
      console.log(res);
      const skeleton = store.getState().entities.explorer.skeleton;

      await handlePublish(
        transformForXml(skeleton),
        newFileName,
        store,
        "draft",
        addToast,
        false
      );
      const versionsList = store.getState().entities.revision?.list;

      const SelectedVersion = versionsList[0];
      store.dispatch({
        type: "revision/setSelectedRevision",
        payload: {
          data: SelectedVersion,
        },
      });
      store.dispatch({
        type: "explorer/displayDraft",
        payload: { draftDisplay: true },
      });

      setEnableBackground(true);
      if (setRevision && handleTransform) {
        setRevision(false);
        setIsTransformed(false);
      }
      store.dispatch({
        type: "editor/updateXMLState",
        payload: { XMLChanged: false },
      });
    } catch (error) {
      addToast(error.message, "error");
    }
  }
  const DropdownOptions = [
    {
      label: "Publish",
      active: true,
      action: () => setShowPublishPopup(true),
    },

    {
      label: "Edit",
      action: () => {
        if (selectedData?.typeFile != "draft") {
          setShowEditPopup(true);
        } else {
          handleEditVersion();
        }
      },
      active: true,
    },
    /////////////////////

    {
      label: "Export",
      active: true,
      action: () => ExportFile(selectedData),
    },
    {
      label: "Compare Files",
      active: true,
      action: () => compareRevision(selectedData),
    },
    {
      label: "Rename",
      action: () => setShowRenamePopup(true),
      active: true,
    },
    {
      label: "Delete",
      action: () => setShowDeletePopup(true),
      active: true,
    },
  ];

  return (
    <div
      className={`${
        selected[data?.filename] ? "revisionItem-selected" : "revisionItem"
      } ${enableBackground ? "enable" : ""}`}
      ref={buttonRef}
    >
      <div
        className="revision-content"
        onClick={draftPresence ? null : handleReadContent}
      >
        <div className="revision-name">
          {/* {showVersion === "true" ? <>{data?.version} - </> : null} */}
          {data?.xmlAlias}
        </div>
        <div className="release-datetime">
          {data?.typeFile != "draft" ? "Deployed" : "Draft"} -{data?.release}
        </div>
      </div>{" "}
      {draftPresence && enableBackground && selected[data?.filename] && (
        <div className="actions-btn">
          <IconButton
            className="moreOptions-btn"
            size="small"
            onClick={(e) => {
              setShowDiscardPopup(true);
            }}
          >
            <ClearIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div>
      )}
      <CustomModal
        open={showDiscardPopup}
        onClose={() => {
          setShowDiscardPopup(false);
        }}
        onClickPrimaryAction={handleDiscard}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDiscardPopup(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Discard"
      >
        <div className="ModalContent">
          <div className="message">
            Are you sure you want to leave edit mode?
          </div>
          <div className="message">Any unsaved changes will be lost!</div>
          {/* <div className="draftVersion">{`Draft of: ${version} - ${xmlAlias}`}</div> */}
        </div>
      </CustomModal>
      {!draftPresence && (
        <div className="actions-btn">
          <Dropdown
            options={DropdownOptions}
            activeDropdown={readOnly}
            withIcon={false}
          >
            <Icon icon="More" size={28} color={themeColors[themeMode].text} />
          </Dropdown>
        </div>
      )}
      <CustomModal
        open={showRenamePopup}
        onClose={() => {
          setShowRenamePopup(false);
        }}
        onClickPrimaryAction={handleEditAlias}
        primaryLabel="Rename"
        modalTitle="Rename"
      >
        <div className="renameContent">
          <TextField
            variant="outlined"
            label="File Name"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: "2px",
                alignItems: "center",
                fontFamily: "Hanken Grotesk, sans-serif",
                fontSize: "16px",
              },
            }}
          />
        </div>
      </CustomModal>
      <CustomModal
        open={showDeletePopup}
        onClose={() => {
          setShowDeletePopup(false);
        }}
        onClickPrimaryAction={handleDelete}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDeletePopup(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Delete"
      >
        <div className="DeleteContent">
          <div className="DeleteMsg">{deleteConfirmation?.confirmQuestion}</div>
          <div className="deleteFileName">
            {deleteConfirmation?.versionName}
          </div>
          <div className="deleteFileDate">
            {deleteConfirmation?.versionDate}
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={showEditPopup}
        onClose={() => {
          setShowEditPopup(false);
        }}
        onClickPrimaryAction={() => {
          changeHandler(selectedData);
          setShowEditPopup(false);
        }}
        primaryLabel="Edit"
        modalTitle="Edit"
      >
        <div className="renameContent">
          <div className="message">
            Choose a name for the draft File of "File Name"
          </div>
          <TextField
            variant="outlined"
            label="File Name"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: "2px",
                alignItems: "center",
                fontFamily: "Hanken Grotesk, sans-serif",
                fontSize: "16px",
              },
            }}
          />
        </div>
      </CustomModal>
      <Modal
        open={showPublishPopup}
        onClose={() => {
          setShowPublishPopup(false);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowPublishPopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowPublishPopup(false);
          handlePublish(
            transformForXml(dataVersion),
            newFileName,
            store,
            "Published",
            addToast,
            true,
            false
          );
        }}
        primaryLabel="Publish"
        secondaryLabe="Cancel"
        modalTitle="Publish"
      >
        <div className="renameContent">
          <CustomInput
            label="File name"
            value={newFileName}
            required={false}
            onChange={(e) => setNewFileName(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RevisionItem;
