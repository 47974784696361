import fetchSysInfo from "../services/fetchSysInfo"

const loadSysInfo = async (setData = () => {}) => {
  try {
    const fetchedData = await fetchSysInfo()
    setData(fetchedData.content)
    console.log(fetchedData)
  } catch (err) {
    console.log(err.message)
  }
}

export default loadSysInfo
