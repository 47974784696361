import imxtools from "imxtools"
import { setIn, getIn } from "lodash-redux-immutability"

export function AddConditionProperty(
  properties = [],
  setProperties,
  store,
  path = [],
  key = ""
) {
  try {
    const id = imxtools.generateUniqueKey(path[path.length - 1] || key)
    const index = store.getState()?.entities?.explorer?.maxOrder || 0

    const newElementContent = {
      id: `imxComponent${id}`,
      itemOrder: index,
      name: "",
      value: "",
    }

    setProperties([...imxtools.toArray(properties), newElementContent])
  } catch (error) {
    console.error("Error in AddConditionProperty:", error)
  }
}

export function AddLookupProperty(
  properties = [],
  setProperties,
  store,
  path = [],
  key = ""
) {
  try {
    const id = imxtools.generateUniqueKey(path[path.length - 1] || key)
    const index = store.getState()?.entities?.explorer?.maxOrder || 0

    const newElementContent = {
      id: `imxComponent${id}`,
      itemOrder: index,
      name: "",
      value: "",
    }

    setProperties([...imxtools.toArray(properties), newElementContent])
  } catch (error) {
    console.error("Error in AddConditionProperty:", error)
  }
}

export function deletePropertyByIndex(array, index, setArray) {
  try {
    if (index < 0 || index >= array.length) {
      console.error("Index out of bounds")
      return
    }
    const newArray = [...array.slice(0, index), ...array.slice(index + 1)]
    setArray(newArray)
  } catch (error) {
    console.error("Error in deletePropertyByIndex:", error)
  }
}

export function updateName(array, setArray, index, key, newValue) {
  try {
    if (index < 0 || index >= array.length) {
      console.error("Index out of bounds")
      return
    }
    const newArray = array.map((item, i) =>
      i === index ? { ...item, [key]: newValue } : item
    )
    setArray(newArray)
  } catch (error) {
    console.error("Error in updateName:", error)
  }
}

export function transformArray(array) {
  try {
    return array.reduce((obj, item) => {
      obj[item.key] = item.value
      return obj
    }, {})
  } catch (error) {
    console.error("Error in transformCondition:", error)
    return {}
  }
}

function addNewUtility(data, store, path, key) {
  try {
    const id = imxtools.generateUniqueKey(path[path.length - 1] || key)
    const index = store.getState()?.entities?.explorer?.maxOrder || 0

    const newCondition = {
      id: `imxComponent${id}`,
      itemOrder: index,
      ...data,
    }

    return newCondition
  } catch (error) {
    console.error("Error in addNewUtility:", error)
    return []
  }
}

export function onSave(
  UtilityType = "",
  createNew = false,
  path = [],
  data = {},
  skeleton = {},
  store
) {
  try {
    let UtilitiesArray = []
    if (createNew) {
      const newUtility = addNewUtility(data, store, path, UtilityType)
      console.log("New Utility:", newUtility)
      console.log("New data:", data)
      const OldUtilitiesArray = getIn(skeleton, path)
      UtilitiesArray = [newUtility, ...OldUtilitiesArray]
    }
    const newData = setIn(skeleton, path, createNew ? UtilitiesArray : data)
    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: { ...newData },
        readOnly: false,
      },
    })
  } catch (error) {
    console.error("Error in onSave:", error)
  }
}
