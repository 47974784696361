import React, { useEffect, useRef, useState } from "react";
import { useStore, useSelector } from "react-redux";
import { getIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import Tab from "../../Tab";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import DropTargetBody from "../../DropTargetBody";
import DragPage from "../../../../../ComponentsLibrary/DragPage";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { useDeviceTheme } from "../../../../../../../../DeviceTheme/DeviceThemeContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";

const DetailScreen = () => {
  const [data, setData] = useState();
  const [parentData, setParentData] = useState();
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState({});
  const [numtabs, setNumTabs] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [path, setPath] = useState([]);
  let store = useStore();
  const scrollRef = useRef(null);
  const { theme, themes } = useDeviceTheme();

  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);
  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit || []
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let navigationPath = useSelector(
    (state) => state?.entities?.deviceview?.navigationPath
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  useEffect(() => {
    const { path } = navigationPath[navigationPath.length - 1]?.props;
    const newPath = path.slice(0, -1);
    setParentData(getIn(skeleton, newPath));
    console.log("pathParent", getIn(skeleton, newPath));
  }, [navigationPath?.length, skeleton]);
  useEffect(() => {
    const { path } = navigationPath[navigationPath.length - 1]?.props;
    const data = getIn(skeleton, path);
    setData(data);
    if (data?.tab) {
      setTabs(imxtools?.toArray(data?.tab));
    }
    setNumTabs(tabs?.length);
    setPath(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPath?.length, tabs?.length, currentTab, getIn(skeleton, path)]);

  useEffect(() => {
    if (parentData?.detail) {
      const handleWheel = (event) => {
        if (!scrollRef.current) return;
        event.preventDefault();
        const scrollSpeedFactor = 5;
        const newScrollPosition = event.deltaY / scrollSpeedFactor;
        scrollRef.current.scrollLeft += newScrollPosition;
      };
      const scrollableElement = scrollRef.current;
      scrollableElement.addEventListener("wheel", handleWheel, {
        passive: false,
      });
      return () => {
        scrollableElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, [data?.detail]);

  const handleTab = (e, currentTab) => {
    e.stopPropagation();
    setCurrentTab(currentTab);
    const tabData = tabs[currentTab] ? tabs[currentTab] : data;
    const tabPath =
      tabs?.length === 1
        ? [...path, "tab"]
        : [...path, "tab", currentTab?.toString()];
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: tabData,
        parent: tabPath,
        component: tabs[currentTab] ? "tab" : "detail",
      },
    });
  };

  const handleNavigateBack = (e) => {
    e.stopPropagation();
    store.dispatch({
      type: "deviceview/navigateBack",
    });
    const { path, data } = navigationPath[navigationPath?.length - 2]?.props;
    handleComponentProp(e, null, data, path, store, path?.[path?.length - 1]);
  };

  ///
  const isTabsEmpty = tabs.length === 0;
  ////
  console.log("akkggg", parentData);
  return (
    <div className="DetailScreen">
      <div
        className="headerDetail"
        style={{
          height: readOnly && "32px",
          maxHeight: readOnly && "32px",
          minHeight: readOnly && "32px",
          flexDirection:
            language?.toLowerCase() === "ar" ? "row-reverse" : "row",
        }}
      >
        <div
          className="goBack"
          style={{ width: readOnly && "32px" }}
          onClick={(e) => handleNavigateBack(e)}
        >
          <Icon
            icon={
              language?.toLowerCase() === "ar"
                ? "chevron-right"
                : "chevron-left"
            }
            className="chevron-left-Details"
            size={20}
          />
        </div>
        {parentData?.detail && (
          <DropTargetBody
            accept={"tab"}
            dragParent={path}
            className={`drop-tabsList-zone ${
              isSelectedComponent(readOnly, path, currentPath)
                ? "selectedComponent"
                : "unSelectedComponent hovered"
            }`}
            onClick={(e) =>
              handleComponentProp(e, null, data, path, store, "detail")
            }
          >
            <div
              className="tabsList"
              ref={scrollRef}
              style={{
                height: !readOnly && "calc(100% - 12px)",
                width: !readOnly && "calc(100% - 20px)",
                maxWidth: !readOnly && "calc(100% - 20px)",
                minWidth: !readOnly && "calc(100% - 20px)",
                margin: !readOnly && "6px 10px",
                gap: !readOnly && "0px",
                flexDirection:
                  language?.toLowerCase() === "ar" ? "row-reverse" : "row",
              }}
            >
              {tabs?.length > 0 ? (
                <React.Fragment>
                  {tabs?.map((tab, index) => {
                    const tabPath = [...path, "tab"];
                    return (
                      <DragPage
                        key={index}
                        index={index}
                        component={"tab"}
                        className={`tabButtom ${!readOnly && "hovered"} ${
                          isSelectedComponent(
                            readOnly,
                            tabs?.length === 1
                              ? tabPath
                              : [...tabPath, index?.toString()],
                            currentPath
                          ) && `selectedComponent`
                        }`}
                        dragParentPath={[...path, "tab"]}
                        dragData={tab}
                        dataAttributes={tabs}
                        setDataAttributes={setTabs}
                        style={{
                          borderBottom:
                            currentTab === index
                              ? `2px solid ${themes?.[theme]?.primary}`
                              : null,
                          // minWidth: !readOnly && "90px",
                          // maxWidth: !readOnly && "90px",
                          color:
                            currentTab === index
                              ? themes?.[theme]?.primary
                              : themes?.[theme]?.textColor,
                        }}
                        onClick={(e) => handleTab(e, index)}
                      >
                        <div key={index} className="tabButtom-label">
                          {tab?.label
                            ? getLabelByLanguage(language, tab)
                            : "Add label"}
                        </div>
                      </DragPage>
                    );
                  })}
                </React.Fragment>
              ) : (
                <div className="tabsList-empty">Add Tab</div>
              )}
            </div>
          </DropTargetBody>
        )}
      </div>
      <div
        className="tab-content"
        style={{
          height: readOnly && "calc(100% - 32px)",
          maxHeight: readOnly && "calc(100% - 32px)",
          minHeight: readOnly && "calc(100% - 32px)",
        }}
        onClick={(e) =>
          parentData?.detail &&
          handleComponentProp(e, null, data, path, store, "detail")
        }
      >
        {tabs?.length > 0 && (
          <Tab
            data={tabs}
            dataTab={tabs[currentTab]}
            currentTab={currentTab}
            path={
              numtabs === 1
                ? [...path, "tab"]
                : [...path, "tab", currentTab?.toString()]
            }
            readOnly={readOnly}
            currentPath={currentPath}
          />
        )}
        <>
          {!parentData?.detail && (
            <DropTargetBody
              accept={["detail"]}
              dragParent={path}
              className="drop-empty-list-zone"
            >
              <div className="empty-list">
                {readOnly ? "No Detail here" : "Drop Detail here"}
              </div>
            </DropTargetBody>
          )}
        </>
      </div>
    </div>
  );
};

export default DetailScreen;
