import React, { useEffect, useContext, useState } from "react";
import { useSelector, useStore } from "react-redux";
import Header from "../../Header";
import PageBody from "../../PageBody";
import PageNav from "../../PageNav";
import imxtools from "imxtools";
import GIS from "../../GIS";
import DropTarget from "../../../services/DropTarget";
import { ComponentsLibraries } from "../../../../../../services/common";
import "./index.scss";
import { handlePage } from "./services/handlePage";
import Modal from "../../Modal";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import DropTargetBody from "../../DropTargetBody";
import DragItemAttribute from "../../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../../services/AppContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import { handleNavigateTo } from "../../PageBody/services";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import useHomeLogic from "./services/useHomeLogic";
import { getIn } from "lodash-redux-immutability";
import welcomepageBg from "./../../../../../../../../assets/maxapps-assets/welcomepageBg.jpg";
import ScrollGuideModel from "../../ScrollGuideModel";

const Home = () => {
  let store = useStore();
  const { setIsDragingIn, setKpiDetail } = useContext(AppContext);
  const useCustomWelcomeBg = process.env.REACT_APP_USE_CUSTOM_WELCOMEBG;

  const {
    skeleton,
    skeletonState,
    readOnly,
    currentPage,
    currentComponent,
    language,
    currentPath,
    openedScrollGuide,
    showScrollGuide,
    dataToSet,
  } = useSelector((state) => ({
    skeleton: state?.entities?.explorer?.skeleton?.maxapps || {},
    skeletonState: state?.entities?.explorer?.skeleton || {},
    readOnly: state?.entities?.explorer?.readOnly,
    currentPage: state?.entities?.deviceview?.currentPage,
    currentComponent: state?.entities?.deviceview?.currentComponent,
    language: state?.entities?.deviceview?.settings?.language || "en",
    currentPath: state?.entities?.editor?.parentToEdit,
    openedScrollGuide: state?.entities?.deviceview?.settings?.openedScrollGuide,
    showScrollGuide: state?.entities?.deviceview?.settings?.showScrollGuide,
    dataToSet: state?.entities?.editor?.dataToSet,
  }));
  const [openModal, setIsOpen] = useState(
    !showScrollGuide ? false : openedScrollGuide
  );
  const [checkedIcon, setCheckedIcon] = useState(showScrollGuide);
  const {
    pages,
    setPages,
    onlyPages,
    numPages,
    gis,
    path,
    setPath,
    pageData,
    openModuleMatrix,
    setOpenModuleMatrix,
    moduleData,
    setModuleData,
    heightPageContent,
    moduleApps,
    setModuleApps,
  } = useHomeLogic();
  useEffect(() => {
    if (!readOnly) {
      store.dispatch({
        type: "editor/dataAdded",
        payload: {
          data: {},
          parent: ["maxapps"],
          component: "maxapps",
        },
      });
    }
  }, [numPages]);
  useEffect(() => {
    if (!pages || pages?.length === 0) {
      store.dispatch({
        type: "editor/dataAdded",
        payload: {
          data: {},
          parent: ["maxapps"],
          component: "maxapps",
        },
      });
      return;
    }
    const path = pages[currentPage]?.gis
      ? ["maxapps", "gis"]
      : (numPages === 1 && !skeleton?.gis) || (numPages === 2 && skeleton?.gis)
      ? ["maxapps", "page"]
      : ["maxapps", "page", currentPage?.toString()];
    setPath(path);
    if (
      pages?.length > 1 &&
      currentComponent?.toLowerCase() === "maxapps" &&
      currentPath?.length <= 1 &&
      dataToSet?.length >= 1
    ) {
      const data = getIn(skeletonState, path);
      store.dispatch({
        type: "editor/dataAdded",
        payload: {
          data: data,
          parent: path,
          component: pages[currentPage]?.gis ? "gis" : "page",
        },
      });
    }
    setModuleApps(moduleData?.apps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numPages, pages, store, skeleton?.gis, moduleData?.apps]);

  const closeModal = () => {
    setIsOpen(false);

    store.dispatch({
      type: "deviceview/setOpenedScrollGuide",
      payload: {
        openedScrollGuide: !openModal,
      },
    });
    console.log("checked ", checkedIcon);
    if (!checkedIcon) {
      store.dispatch({
        type: "deviceview/setShowScrollGuide",
        payload: {
          showScrollGuide: false,
        },
      });
    }
  };
  const handleChange = () => {
    console.log("checked");
    setCheckedIcon(!checkedIcon);
  };
  return (
    <div
      className="Home"
      style={{
        background:
          useCustomWelcomeBg === "false"
            ? `url(${welcomepageBg}) center/cover no-repeat`
            : "white",
      }}
    >
      <Modal openModal={openModal} tab={true} isSideBar={true} bg="#22222238">
        <ScrollGuideModel closeModal={closeModal} handleChange={handleChange} />
      </Modal>
      <Header pages={pages} currentPage={currentPage} skeleton={skeleton} />
      {pages && pages?.length !== 0 ? (
        <React.Fragment>
          {pages[currentPage]?.gis ? (
            <div
              className="Page"
              onClick={(e) =>
                handlePage(
                  e,
                  currentPage,
                  setPath,
                  store,
                  pages,
                  numPages,
                  skeleton
                )
              }
              style={{
                height: heightPageContent,
                maxHeight: heightPageContent,
                minHeight: heightPageContent,
              }}
            >
              <GIS path={path} data={gis} />
            </div>
          ) : (
            <div
              className="Page"
              onClick={(e) =>
                handlePage(
                  e,
                  currentPage,
                  setPath,
                  store,
                  pages,
                  numPages,
                  skeleton
                )
              }
              style={{
                height: heightPageContent,
                maxHeight: heightPageContent,
                minHeight: heightPageContent,
              }}
              dropPath={path}
              accept={Object.keys(ComponentsLibraries?.page)}
              homeScreen={true}
            >
              {imxtools?.toArray(pages[currentPage]?.app ?? [])?.length === 0 &&
              imxtools?.toArray(pages[currentPage]?.module ?? [])?.length ===
                0 &&
              imxtools?.toArray(pages[currentPage]?.row ?? [])?.length === 0 &&
              imxtools?.toArray(pages[currentPage]?.kpi ?? [])?.length === 0 ? (
                <DropTarget
                  className={`drop-page-empty-zone ${!readOnly && "hoverComp"}`}
                  dragParentPath={["maxapps", "page"]}
                  dropPath={path}
                  accept={Object.keys(ComponentsLibraries?.page)}
                  homeScreen={true}
                >
                  <div className={`EmptyPage ${readOnly && "void"}`}>
                    {readOnly ? "Empty page" : "Drop Components here"}
                  </div>
                </DropTarget>
              ) : (
                <PageBody
                  PageContent={pageData}
                  path={path}
                  pages={pages}
                  currentPage={currentPage}
                  readOnly={readOnly}
                  setOpenModuleMatrix={setOpenModuleMatrix}
                  setModuleData={setModuleData}
                />
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div
          className="Page"
          onClick={(e) =>
            handleComponentProp(
              e,
              null,
              skeleton,
              ["maxapps"],
              store,
              "maxapps"
            )
          }
          style={{
            height: heightPageContent,
            maxHeight: heightPageContent,
            minHeight: heightPageContent,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      )}
      {numPages < 1 && !readOnly && (
        <DropTarget
          className="footer-nav NoPage "
          accept={Object.keys(ComponentsLibraries?.maxapps)}
          dropPath={["maxapps"]}
          homeScreen={true}
          onClick={() => setKpiDetail(false)}
        >
          insert Page/GIS
        </DropTarget>
      )}
      {((numPages === 1 && !readOnly) || numPages > 1) && (
        <div className="footer-nav">
          <PageNav
            pages={pages}
            numPages={numPages}
            handlePage={handlePage}
            readOnly={readOnly}
            gisOutSidePage={gis}
            path={path}
            indexGis={onlyPages?.length}
            setPages={setPages}
            isGIS={skeleton?.gis ?? false}
            skeleton={skeleton}
            setPath={setPath}
            onlyPages={onlyPages}
          />
        </div>
      )}
      {/* Module Modal  */}
      <Modal openModal={openModuleMatrix} bg="#22222238">
        <div
          className="module-modal-container"
          onClick={() => setOpenModuleMatrix(false)}
        >
          <div className="module-modal" onClick={(e) => e.stopPropagation()}>
            <div className="module-modal-title">
              <div>
                {moduleData?.label
                  ? getLabelByLanguage(language, moduleData)
                  : "Add Label"}
              </div>
              <div onClick={() => setOpenModuleMatrix(false)}>
                <Icon icon="times" size={13} />
              </div>
            </div>
            <DropTargetBody
              accept={["app"]}
              dragParent={
                moduleData?.numModule === 1
                  ? [moduleData?.path, "module"]
                  : [
                      moduleData?.path,
                      "module",
                      moduleData?.indexModule?.toString(),
                    ]
              }
              className="module-modal-apps"
            >
              {moduleApps?.length > 0 ? (
                imxtools
                  ?.toArray(moduleApps ?? {})
                  ?.map((appModule, indexAppModule) => {
                    const appsLength = moduleApps?.length;
                    return (
                      <DragItemAttribute
                        index={indexAppModule}
                        className={`modal-app ${
                          appsLength - 1 === indexAppModule && "last-modal-app"
                        }`}
                        component={"app"}
                        dragParentPath={
                          moduleData?.numModule === 1
                            ? [...moduleData?.path, "module"]
                            : [
                                ...moduleData?.path,
                                "module",
                                moduleData?.indexModule?.toString(),
                              ]
                        }
                        dragData={appModule}
                        dataAttributes={moduleApps}
                        setDataAttributes={setModuleApps}
                        setIsDraging={setIsDragingIn}
                      >
                        <div
                          className={`modal-app ${
                            isSelectedComponent(
                              readOnly,
                              moduleData?.numModule === 1
                                ? [...moduleData?.path, "module"]
                                : [
                                    ...moduleData?.path,
                                    "module",
                                    moduleData?.indexModule?.toString(),
                                  ],
                              currentPath
                            ) && "selectedComponent"
                          }`}
                          onClick={(e) =>
                            handleComponentProp(
                              e,
                              appsLength === 1
                                ? null
                                : indexAppModule?.toString(),
                              appModule,
                              moduleData?.numModule === 1
                                ? [...moduleData?.path, "module"]
                                : [
                                    ...moduleData?.path,
                                    "module",
                                    moduleData?.indexModule?.toString(),
                                  ],
                              store,
                              "app"
                            )
                          }
                          onDoubleClick={(event) =>
                            handleNavigateTo(
                              event,
                              indexAppModule,
                              "app",
                              null,

                              appModule,
                              store,
                              moduleData?.numModule === 1
                                ? [...moduleData?.path, "module"]
                                : [
                                    ...moduleData?.path,
                                    "module",
                                    moduleData?.indexModule?.toString(),
                                  ]
                            )
                          }
                        >
                          <div className="appModuleIcon">
                            <Icon
                              icon={
                                appModule?.icon ? appModule?.icon : "notoper"
                              }
                              size={22}
                            />
                          </div>
                          <div className="appModuleLabel">
                            {appModule?.label
                              ? getLabelByLanguage(language, appModule)
                              : "Add label"}
                          </div>
                        </div>
                      </DragItemAttribute>
                    );
                  })
              ) : (
                <div className="drop-apps-empty-module-matrix">
                  Drop Apps Here
                </div>
              )}
            </DropTargetBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
