import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import "./index.scss";

import Lottie from "react-lottie";
import { HandleStepChange } from "../../../services/stepChange/stepChange";
import { LoadingOptions } from "../data";

import { checkVerif } from "./services/index";
import CustomButton from "../../../../../components/CustomButton";
import { useTheme } from "../../../../../theme/themeContext";

function SyncConfigComponent({
  maxappsHostAdress = "",
  title = "",
  showFooter = true,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [verifInstall, setVerifInstall] = useState(true);
  const [isVerifying, setIsVerifying] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputAddress, setInputAddress] = useState(maxappsHostAdress);
  const { themeMode, themeColors } = useTheme();
  const store = useStore();
  useEffect(() => {
    setInputAddress(maxappsHostAdress);
  }, [maxappsHostAdress]);

  useEffect(() => {
    checkVerif(
      inputAddress,
      setIsChecked,
      setIsVerifying,
      setVerifInstall,
      setErrorMessage
    );
  }, []);

  const handleInputChange = (event) => {
    setInputAddress(event.target.value);
  };

  return (
    <div className="SyncConfigContainer">
      <div className="SyncConfig-body">
        <div className="Sync-ipAdress">{title}</div>
        {errorMessage ? (
          <div className="Sync-error">{errorMessage}</div>
        ) : isChecked ? (
          <Lottie
            options={LoadingOptions}
            height={50}
            width={60}
            isClickToPauseDisabled={true}
            style={{
              cursor: "auto",
            }}
          />
        ) : (
          <div className="Sync-error"></div>
        )}

        <input
          className="SyncInput"
          value={inputAddress}
          onChange={handleInputChange}
        ></input>
      </div>
      {showFooter ? (
        <div className="SyncConfig-footer">
          <button
            className="SyncConfig-skip-button"
            onClick={() => {
              HandleStepChange(store, "Disconnect");
            }}
          >
            Skip
          </button>
          <button
            className="SyncConfig-submit-button"
            onClick={() =>
              checkVerif(
                inputAddress,
                setIsChecked,
                setIsVerifying,
                setVerifInstall,
                setErrorMessage
              )
            }
          >
            Submit
          </button>
        </div>
      ) : (
        <CustomButton
          label={"Submit"}
          backgroundColor={themeColors[themeMode].SupportButtonBg}
          hoveredBackground={themeColors[themeMode].SupportButtonBg}
          ActiveBackground={themeColors[themeMode].SupportButtonBg}
          widthBtn="100%"
          heightBtn="2.75rem"
          color="white"
          borderColor="var(--ButtonSelected)"
          borderWidth="1px"
          fontSize={16}
          borderRadius={2}
          onClick={() =>
            checkVerif(
              inputAddress,
              setIsChecked,
              setIsVerifying,
              setVerifInstall,
              setErrorMessage
            )
          }
        />
      )}
    </div>
  );
}

export default SyncConfigComponent;
