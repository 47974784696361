import getCredentials from "../services/MonitorCredentials"
import DataCollectionFn from "../services/DataCollection"

const loadData = async (
  endPoint = "",
  setData = () => {},
  cleaningFunction = () => {}
) => {
  try {
    const credentials = getCredentials()
    const fetchedData = await DataCollectionFn(
      credentials?.login,
      credentials?.password,
      endPoint
    )
    const cleanedData = cleaningFunction(fetchedData)
    setData(cleanedData)
  } catch (err) {
    console.log(err.message)
  }
}

export default loadData
