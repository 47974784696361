import getFileContent from "../getFileContent";

async function handleEditContentVersion(
  addToast,
  store,
  filenametoGetContent,
  order,
  data = {}
) {
  const res = await getFileContent(
    store,
    filenametoGetContent,
    false,
    addToast
  );

  if (res?.data?.success) {
    const skeleton = store.getState().entities.explorer.skeleton;
    console.log("SkeletonFromEdit", skeleton);
    store.dispatch({
      type: "explorer/incrementOrder",
      payload: { order },
    });

    store.dispatch({
      type: "explorer/displayDraft",
      payload: { draftDisplay: true },
    });

    const payload = filenametoGetContent
      ? { obj: { [filenametoGetContent]: true } }
      : {};

    store.dispatch({
      type: "revision/setSelectedItem",
      payload,
    });

    store.dispatch({
      type: "explorer/addDraftSkeleton",
      payload: {
        skeleton,
      },
    });
  }
}

export default handleEditContentVersion;
