import React, { useContext, useEffect } from "react";
import Property from "../../../../../../components/Property";
import { AppContext } from "../../../../services/AppContext";

function SimpleProp({
  readOnly = true,
  data = {},
  props = [],
  setProps,
  XMLChanged = false,
}) {
  const { emptyIndex } = useContext(AppContext);
  useEffect(() => {
    const simpleProps = Object.keys(data ?? {})
      ?.filter(
        (att) =>
          att?.toString() !== "condition" &&
          att?.toString() !== "lookup" &&
          !data[att]?.includes("#FCT")
      )
      ?.reduce((accumulator, key) => {
        accumulator.push({ key, value: data[key] });
        return accumulator;
      }, []);
    setProps(simpleProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  console.log("emptyIndex", emptyIndex);
  return (
    <>
      {props &&
        props?.map((prop, index) => {
          return (
            <div
              key={index}
              className="propertie"
              style={{
                gridTemplateColumns: readOnly
                  ? "150px calc(100% - 150px)"
                  : "150px calc(100% - 175px) 25px",
              }}
            >
              <Property
                Key={prop?.key}
                Value={prop?.value ? prop?.value : readOnly ? "--" : ""}
                disabled={readOnly ?? true}
                maxRows={4}
                multiline={true}
                index={index}
                data={props}
                setData={setProps}
                readOnly={readOnly}
                XMLChanged={XMLChanged}
                emptyIndex={prop?.key == emptyIndex}
              />
            </div>
          );
        })}
    </>
  );
}

export default SimpleProp;
