import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import getSocketConfig from '../services/getSocketConfig';
import { useSelector } from 'react-redux';
import { getMode } from '../components/Interceptor/services/getMode';
const nameSpace = '/interceptorRequestResponse';

function useInterceptorSocket() {
  const { requestsResponsePairs: pairsRedux } = useSelector(
    (state) => state?.entities?.interceptor ?? {}
  );

  const [socketStatus, setSocketStatus] = useState(false);
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const [mode, setMode] = useState(null);
  const [requestResponsePairs, setRequestResponsePairs] = useState(pairsRedux);

  // useEffect(() => {
  //   if (pairsRedux.length > 0) {
  //     setRequestResponsePairs(pairsRedux);
  //   }
  // }, [pairsRedux]);

  useEffect(() => {
    //loading first
    getMode().then((data) => {
      setMode(data);
    });
  }, []);

  const startSocket = useCallback(
    (callback = () => {}) => {
      if (mode === null) {
        return;
      }
      if (!mode?.httpInterceptor) {
        return;
      }
      try {
        socket?.connect();
        socket?.on('connect', () => {
          setSocketStatus(true);
          socket.emit('getRequestsResponse', {});
        });

        callback(true);
      } catch (error) {
        console.error('socket connect error:', error);
      }
    },
    [socket, mode?.httpInterceptor, mode]
  );

  const stopSocket = useCallback(
    (callback = () => {}) => {
      try {
        if (socket) {
          socket.disconnect();
          socket.close();
          setSocketStatus(false);
          callback(false);
        }
      } catch (error) {
        console.error('socket disconnect error:', error);
      }
    },
    [socket]
  );

  useEffect(() => {
    if (mode === null) {
      // Mode hasn't been fetched yet, wait
      return;
    }
    if (mode?.httpInterceptor === false) {
      setIsLoading(false);
      stopSocket();
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const { socketAddress, protocol } = await getSocketConfig().then(
          (res) => res?.content?.server
        );
        // const socketAddress = 'localhost:4105';
        // const protocol = 'https';

        const { hostname: windowaddress } = window.location;
        const socketInstance = io(
          `${
            socketAddress ? `${protocol}://${socketAddress}` : windowaddress
          }${nameSpace}`,
          {
            rejectUnauthorized: false,
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
          }
        );
        setSocket(socketInstance);
      } catch (error) {
        console.error('Error fetching Maximo config:', error);
      } finally {
        setIsLoading(false); // Set loading to false when done, regardless of success or failure
      }

      return () => {
        socket?.disconnect();
      };
    })();
  }, [mode]);

  useEffect(() => {
    if (socket && mode?.httpInterceptor) {
      startSocket(setSocketStatus);

      socket?.on('requests', (data) => {
        setRequestResponsePairs((prevPairs) => {
          const existingPairIndex = prevPairs.findIndex(
            (pair) =>
              pair.request?.requestData?.meta?.requestId ===
              data?.request?.requestData?.meta?.requestId
          );

          if (existingPairIndex === -1) {
            // Checking if there's already a response for this request
            const responseIndex = prevPairs.findIndex(
              (pair) =>
                pair.response?.responseMetadata?.requestId ===
                data?.request?.requestData?.meta?.requestId
            );

            if (responseIndex !== -1) {
              // If there's a response, update that pair with the request
              const updatedPairs = [...prevPairs];
              updatedPairs[responseIndex] = {
                ...updatedPairs[responseIndex],
                request: {
                  requestUrl: data?.request?.requestUrl,
                  requestData: data?.request?.requestData,
                  host: data?.request?.host,
                  pathAndQuery: data?.request?.pathAndQuery,
                },
              };
              return updatedPairs;
            } else {
              // If there's no response yet, create a new pair
              return [
                ...prevPairs,
                {
                  request: {
                    requestUrl: data?.request?.requestUrl,
                    requestData: data?.request?.requestData,
                    host: data?.request?.host,
                    pathAndQuery: data?.request?.pathAndQuery,
                  },
                  response: null,
                },
              ];
            }
          }

          return prevPairs;
        });
      });

      socket?.on('responses', (data) => {
        const requestId = data?.response?.responseMetadata?.requestId;

        setRequestResponsePairs((prevPairs) => {
          const pairIndex = prevPairs.findIndex(
            (pair) => pair.request?.requestData?.meta?.requestId === requestId
          );

          if (pairIndex !== -1) {
            // If there's a matching request, update that pair with the response
            const updatedPairs = [...prevPairs];
            updatedPairs[pairIndex] = {
              ...updatedPairs[pairIndex],
              response: data?.response || 'no response',
            };
            return updatedPairs;
          } else {
            // If there's no matching request, create a new pair with just the response
            return [
              ...prevPairs,
              {
                request: null,
                response: data?.response,
              },
            ];
          }
        });
      });

      socket.on('connect_error', () => {
        setSocketStatus(false);
      });

      socket.on('reconnect', () => {
        setSocketStatus(true);
      });

      socket.on('disconnect', () => {
        setSocketStatus(false);
      });

      return () => {
        socket.off('requestInterceptor');
        socket.off('responseInterceptor');
        socket.off('connect_error');
        socket.off('reconnect');
        socket.off('disconnect');
        socket.off('requests');
        socket.off('getRequestsResponse');
        socket.off('responses');
        stopSocket(setSocketStatus);
      };
    }
  }, [socket, startSocket, stopSocket, mode]);

  return {
    socket,
    startSocket,
    stopSocket,
    isLoadingSocket: isLoading,
    requestResponsePairs,
    setRequestResponsePairs,
    mode,
    setMode,
  };
}

export default useInterceptorSocket;
