import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import imxtools from "imxtools";
import xml2js from "minexml2js";
import { useStore, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import useToasts from "../../../../components/CustomToast/useToasts";
import CustomButton from "../../../../components/CustomButton";
import transformForXml from "../../services/helpers/transformForXml";
import Modal from "../../../../components/CustomModal";
import { useLocation } from "react-router-dom";
import handleNewSkeleton from "../../services/helpers/handleNewSkeleton";
import handlePublish from "./services/handlePublish";
import saveFile from "./services/saveFile";
import { getButtonStyles } from "./services/getButtonStyles ";
import transformForStore from "../../services/helpers/transformForStore";
import Icon from "../../../../components/CustomIcon/Icon";
import handleEditContentVersion from "../../services/helpers/handleEditContentVersion";
import handleClickedItem from "../../services/helpers/handleClickedItem";
import CustomInput from "../../../../components/CustomInput";
import { useTheme } from "../../../../theme/themeContext";
import HandleEditMode from "../CompareFiles/services/handleEditMode";
import { arrayToObject, handleSave } from "../../services/helpers";
import handleUpdate from "./services/handleSaveDraft";
import { getObjectValues } from "../Properties/service";
import { useSaveProps } from "../../services/savePropsContext";
import { getIn, setIn } from "lodash-redux-immutability";
import handleSaveRevision from "../../services/helpers/handleSaveRevison";
import CustomModal from "../../../../components/CustomModal";
import Switcher from "../../../../components/Switcher";
import getAllFiles from "../../services/getAllFiles";
import Pointer from "../ComponentsLibrary/Assets/Pointer.svg";
import NotAllowed from "../ComponentsLibrary/Assets/NotAllowed.svg";
import transformJson from "../../services/helpers/tranformJson";
import { AppContext } from "../../services/AppContext";
import { compareArraysOfObjects } from "../../../../utils/util";

function AppBar({
  revisionSelected = {},
  setCompareXML = () => {},
  compareXML = false,
  XMLChanged = false,
  xmlValue,
  revision,
  setRevision = () => {},
  handleTransform,
  setIsTransformed = () => {},
  setEnableBackground = () => {},
  setSaveComplete = () => {},
  setHandleEditorClick = () => {},
  setChanged = () => {},
  handleEditorClick,
  setBorder = () => {},
}) {
  const { setEmptyKey, setEmptyValue, setEmptyIndex } = useContext(AppContext);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [newFileName, setNewFileName] = useState(revisionSelected?.xmlAlias);
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const [openModal, setOpenModal] = useState(false);
  const [data2set, setData2set] = useState({});
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { simpleProps, conditions, lookup, scripts } = useSaveProps();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [RevisionBtn, setRevisionBtn] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [filteredProps, setFilteredProps] = useState([]);
  const [simpleProp, setSimpleProp] = useState({});
  const [editorDisabled, setEditorDisabled] = useState(false);
  const [oldData, setOldData] = useState({});

  // const res_equal = compareArraysOfObjects(oldData, [
  //   ...simpleProps,
  //   ...scripts,
  //   lookup,
  //   conditions,
  // ]);

  const { res_equal } = useSaveProps();

  let store = useStore();
  let hiddenFileInput = useRef(null);
  let location = useLocation();
  const { addToast } = useToasts();
  const { themeMode, themeColors } = useTheme();
  const {
    data,
    edit,
    readOnly,
    compare,
    showRight,
    leftFileName,
    showLeft,
    itemLeftSelected,
    leftCode,
    typeFile,
    dataVersion,
  } = useSelector((state) => ({
    data: state?.entities?.explorer?.skeleton || {},
    edit: state?.entities?.editor || {},
    readOnly: state?.entities?.explorer?.readOnly || false,
    compare: state?.entities?.compareFiles?.compare || false,
    showRight: state?.entities?.compareFiles?.rightCode?.active,
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    showLeft: state?.entities?.compareFiles?.leftCode?.active,
    leftCode: state?.entities?.compareFiles?.leftCode?.code || "",
    itemLeftSelected:
      state?.entities?.compareFiles?.leftCode?.fileSelected?.itemLeftSelected ||
      {},
    typeFile: state?.entities?.revision?.revisionSelected?.typeFile,
    dataVersion: state?.entities?.explorer?.skeleton || {},
  }));
  const [uncompletProp, setUncompletProp] = useState(false);
  const commonButtonStyles = getButtonStyles(
    readOnly,
    themeColors,
    themeMode,
    false,
    false
  );
  const {
    dataToSet: datatoSet,
    parentToEdit: parent,
    component: componentEdited,
  } = edit;

  let skeleton = useSelector(
    (state) => state?.entities?.explorer?.skeleton || {}
  );

  useEffect(() => {
    const filteredProps = simpleProps.filter((item) => {
      // Include items where not both key and value are empty
      return (
        !(item.key == null || item.key.trim() === "") ||
        !(item.value == null || item.value.trim() === "")
      );
    });

    // Filter out items where only key or only value is present
    const furtherFilteredProps = filteredProps.filter((item) => {
      // Include items where both key and value are present
      return !(
        item.key == null ||
        item.key.trim() === "" ||
        item.value == null ||
        item.value.trim() === ""
      );
    });

    const simpleProp = arrayToObject(
      [...furtherFilteredProps, ...scripts, lookup, conditions].filter(
        (item) => Object?.keys(item)?.length > 0
      ),
      addToast
    );

    setFilteredProps(furtherFilteredProps);
    setSimpleProp(simpleProp);
  }, [simpleProps, addToast, scripts, lookup, conditions]);

  let key = imxtools?.generateUniqueKey(
    parent?.length < 1 ? "maxapps" : parent?.[parent?.length - 1]
  );

  useEffect(() => {
    const _filteredData = Object.keys(datatoSet ?? {})
      ?.filter(
        (att) =>
          att?.toString() !== "id" &&
          att?.toLowerCase() !== "itemorder" &&
          typeof datatoSet?.[att] !== "object"
      )
      ?.reduce((filteredObject, key) => {
        filteredObject[key] = datatoSet?.[key];
        return filteredObject;
      }, {});

    setData2set(_filteredData);
  }, [datatoSet]);


  useEffect(() => {
    setEditorDisabled(!res_equal);
  }, [res_equal]);

  useEffect(() => {
    setEditorDisabled(!res_equal);
  }, [res_equal]);

  useEffect(() => {
    setEditorDisabled(!res_equal);
  }, [res_equal]);

  useEffect(() => {
    const onKeyDown = (e) => {
      try {
        if (e.ctrlKey && e.key === "s") {
          e.preventDefault();
          if (!readOnly) {
            handleSave(
              data2set,
              store,
              parent,
              getObjectValues(datatoSet),
              addToast,
              readOnly,
              [...simpleProps, ...scripts, lookup, conditions]
            );
          }
        }
      } catch (error) {
        console.error(`Error in handleKeyDown for save: ${error}`);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [readOnly, data2set, parent, simpleProps, scripts, lookup, conditions]);

  useEffect(() => {
    async function fetchFiles() {
      await getAllFiles(store, addToast);
    }
    fetchFiles();
  }, []);

  useEffect(() => {
    setNewFileName(revisionSelected?.xmlAlias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);

  const newDraft = async () => {
    await handleNewSkeleton(store, newFileName, addToast);
    setEnableBackground(true);
  };

  const handleEditVersion = async () => {
    // if (compareXML && showLeft) {
    setEnableBackground(true);
    if (typeFile === "Published") {
      setShowEditPopup(true);
      if (compare) {
        const searchRegExp = new RegExp("&", "g");
        const xmlData = leftCode.replace(searchRegExp, "&amp;");
        const parser = new xml2js.Parser({
          explicitArray: false,
          emptyTag: { $: { id: "mockId" } },
        });
        const { maxOrder, result } = await parser.parseStringPromise(xmlData);

        await handleEditContentVersion(
          addToast,
          store,
          itemLeftSelected?.filename,
          maxOrder,
          itemLeftSelected
        );
      } else {
        changeHandler(revisionSelected);
        setBorder(false);
      }
    } else {
      await handleEditContentVersion(
        addToast,
        store,
        revisionSelected?.filename,
        revisionSelected?.maxOrder
      );

      store.dispatch({
        type: "editor/updateXMLState",
        payload: { XMLChanged: false },
      });
    }
    if (showRight) {
      store.dispatch({
        type: "compareFiles/updateCompareState",
        payload: {
          compare: true,
        },
      });
    }
    if (!compareXML) {
      HandleEditMode(
        store,
        showLeft,
        showRight,
        leftFileName,
        isOn,
        iconClicked
      );
    }
    if (setRevision && handleTransform) {
      setRevision(false);
      setIsTransformed(false);
    }
  };

  const handleDiscard = async () => {
    await handleClickedItem(
      addToast,
      store,
      skeleton,
      // lastVersion?.filename,
      revisionSelected?.filename,
      false,
      true
    );
    const searchRegExp = new RegExp("&", "g");
    const xmlData = xmlValue.replace(searchRegExp, "&amp;");
    const { result } = await new xml2js.Parser({
      explicitArray: false,
      emptyTag: { $: { id: "mockId" } },
    }).parseStringPromise(xmlData);
    const data = await transformForStore(result);
    store.dispatch({
      type: "explorer/updateData",
      payload: {
        skeleton: skeleton,
        readOnly: true,
      },
    });

    store.dispatch({
      type: "editor/updateData",
      payload: {
        data: skeleton,
      },
    });

    handleUpdate(revisionSelected?.filename, transformJson(skeleton), store);
    setEnableBackground(false);
    if (!showLeft || !showRight) {
      store.dispatch({
        type: "compareFiles/updateCompareState",
        payload: {
          compare: false,
        },
      });
    }
    if (!compareXML) {
      HandleEditMode(
        store,
        showLeft,
        showRight,
        leftFileName,
        isOn,
        iconClicked
      );
    }
  };

  const handleSaveAndUpdate = async () => {
    const current = store?.getState()?.entities?.editor?.parentToEdit || [];
    const component = store?.getState()?.entities?.editor?.component;

    const emptyKeyProps = [];
    const emptyValueProps = [];
    let isUncompletProp = false;

    const filteredProps = simpleProps.filter((item) => {
      // Include items where not both key and value are empty
      return (
        !(item.key == null || item.key.trim() === "") ||
        !(item.value == null || item.value.trim() === "")
      );
    });

    filteredProps.forEach((item) => {
      if (
        (item.key == null || item.key.trim() === "") &&
        !(item.value == null || item.value.trim() === "")
      ) {
        emptyKeyProps.push(item);
      }
      if (
        !(item.key == null || item.key.trim() === "") &&
        (item.value == null || item.value.trim() === "")
      ) {
        emptyValueProps.push(item);
      }
    });

    if (emptyKeyProps.length > 0) {
      setEmptyKey(true);
      setEmptyIndex(emptyKeyProps[0].key);
      addToast("Empty key detected, please check.", "warn");
      isUncompletProp = true;
    } else {
      setEmptyKey(false);
    }

    if (emptyValueProps.length > 0) {
      setEmptyValue(true);
      setEmptyIndex(emptyValueProps[0].key);
      addToast("Empty value detected, please check.", "warn");
      isUncompletProp = true;
    } else {
      setEmptyValue(false);
    }

    setUncompletProp(isUncompletProp);

    // Filter out items where only key or only value is present
    const furtherFilteredProps = filteredProps.filter((item) => {
      // Include items where both key and value are present
      return !(
        item.key == null ||
        item.key.trim() === "" ||
        item.value == null ||
        item.value.trim() === ""
      );
    });

    const simpleProp = arrayToObject(
      [...furtherFilteredProps, ...scripts, lookup, conditions].filter(
        (item) => Object?.keys(item)?.length > 0
      ),
      addToast
    );

    if (!isUncompletProp) {
      if (!XMLChanged) {
        const dataEdit = getIn(skeleton, current);

        const finalData = {
          ...getObjectValues(dataEdit),
          ...data2set?.id,
          ...data2set?.itemOrder,
          ...simpleProp,
          id: `imxComponent${key}`,
        };

        const newData = setIn(skeleton, parent, finalData);
        setSaveComplete(true);
        setHandleEditorClick(false);
        setChanged(false);

        await handleSave(newData, store, addToast, readOnly, finalData, [
          ...filteredProps,
          ...scripts,
          lookup,
          conditions,
        ]);

        await handleUpdate(
          revisionSelected?.filename,
          transformForXml(skeleton),
          store
        );

        store.dispatch({
          type: "editor/updateXMLState",
          payload: { XMLChanged: false },
        });
        console.log("saved here");
      } else {
        try {
          const searchRegExp = new RegExp("&", "g");
          const xmlData = xmlValue.replace(searchRegExp, "&amp;");
          const { result } = await new xml2js.Parser({
            explicitArray: false,
            emptyTag: { $: { id: "mockId" } },
          }).parseStringPromise(xmlData);
          const skeleton = await transformForStore(result);
          handleSaveRevision(store, skeleton, addToast);
          handleUpdate(
            revisionSelected?.filename,
            transformForXml(skeleton),
            store
          );
          store.dispatch({
            type: "editor/updateXMLState",
            payload: { XMLChanged: false },
          });

          setSaveComplete(true);
          setHandleEditorClick(false);
        } catch (error) {
          addToast(error.message, "error");
        }
      }
    }
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      try {
        if (
          (!readOnly &&
            (e.ctrlKey || e.metaKey) &&
            (e.key === "s" || e.key === "S")) ||
          (!handleEditorClick &&
            editorDisabled &&
            !readOnly &&
            e.key === "Enter" &&
            !e.shiftKey &&
            !e.altKey &&
            !e.ctrlKey &&
            !e.metaKey)
        ) {
          e.preventDefault();
          if (!readOnly) {
            handleSaveAndUpdate();
          }
        }
      } catch (error) {
        console.error(`Error in handleKeyDown for save: ${error}`);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [readOnly, handleSaveAndUpdate, handleEditorClick]);

  function handleClick() {
    hiddenFileInput.current.click();
    setNewFileName("");
  }

  async function changeHandler(selectedFile) {
    store.dispatch({
      type: "revision/setSelectedItem",
      payload: {
        obj: {},
      },
    });
    store.dispatch({
      type: "revision/setSelectedRevision",
      payload: { data: {} },
    });
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onloadend = async () => {
      try {
        const searchRegExp = new RegExp("&", "g");
        const xmlData = reader.result.replace(searchRegExp, "&amp;");
        const { maxOrder, result } = await new xml2js.Parser({
          explicitArray: false,
          emptyTag: { $: { id: "mockId" } },
        }).parseStringPromise(xmlData);
        const skeleton = await transformForStore(result);
        await handlePublish(
          transformForXml(skeleton),
          newFileName,
          store,
          "draft",
          addToast,
          true,
          false
        );
        const versionsList = store.getState().entities.revision?.list;

        const SelectedVersion = versionsList[0];
        store.dispatch({
          type: "revision/setSelectedRevision",
          payload: {
            data: SelectedVersion,
          },
        });
        store.dispatch({
          type: "deviceview/navigateToHome",
        });
        store.dispatch({
          type: "deviceview/setCurrentPage",
          payload: {
            currentPage: 0,
          },
        });
        store.dispatch({
          type: "explorer/displayDraft",
          payload: { draftDisplay: false },
        });
        hiddenFileInput.current.value = null;
        // await creactDraft(store, skeleton, maxOrder);
      } catch (error) {
        addToast(error.message, "error");
      }
    };
  }

  function handleChange(event = null, fileName = newFileName) {
    if (!event) return;
    const fileUploaded = event?.target?.files[0] || null;
    setOpenUploadModal(true);
    fileUploaded && changeHandler(fileUploaded, fileName);
    // hiddenFileInput.current.value = null;
  }
  const handleResetChanges = () => {
    store.dispatch({
      type: "compareFiles/updateResetChangesState",
      payload: {
        resetChanges: true,
      },
    });
  };
  

  // console.log("zerger",editorDisabled);

  return (
    <React.Fragment>
      <div className="appBar">
        <div className="leftButtons">
          <CustomButton
            {...commonButtonStyles}
            iconName="folder-closed"
            withLabel={revision}
            label={revision ? "Revision" : ""}
            widthBtn={revision ? "20.05rem" : ""}
            iconClose={revision && "close1"}
            onClick={() => {
              setRevisionBtn(true);
              handleTransform();
              setRevision(!revision);
            }}
            borderRight={false}
            justifyContent={"space-between"}
            iconColor={revision ? "white" : themeColors[themeMode].iconColor}
            backgroundColor={
              revision
                ? themeColors[themeMode].SupportButtonBg
                : themeColors[themeMode].LoginBG
            }
            color={revision ? "white" : themeColors[themeMode].iconColor}
            tooltip="Revision"
            cursor={`url(${Pointer}), auto`}
            customStyle={true}
          />
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            secondaryAction={true}
            onClickSecondaryAction={() => {
              setOpenModal(false);
            }}
            onClickPrimaryAction={() => {
              setOpenModal(false);
            }}
            primaryLabel="Save"
            secondaryLabe="Cancel"
            modalTitle="Save"
          >
            <div className="renameContent">
              <CustomInput
                label="File name"
                value={newFileName}
                // required={true}
                onChange={(e) => setNewFileName(e.target.value)}
              />
            </div>
          </Modal>
          <Modal
            open={openNewModal}
            onClose={() => {
              setOpenNewModal(false);
            }}
            onClickPrimaryAction={(event) => {
              setOpenNewModal(false);
              newDraft();
              setRevision(false);
              setIsTransformed(false);
            }}
            primaryLabel="Create"
            modalTitle="New File"
          >
            <div className="renameContent">
              <CustomInput
                label="File name"
                placeholder={"Untitled"}
                onChange={(e) => setNewFileName(e.target.value)}
              />
            </div>
          </Modal>
          <CustomButton
            {...commonButtonStyles}
            iconName="addNew"
            withLabel={false}
            onClick={() => (readOnly ? setOpenNewModal(true) : undefined)}
            borderRight={true}
            borderLeft={true}
            tooltip="New Xml"
            cursor={`url(${readOnly ? Pointer : NotAllowed}), auto`}
          />

          <Modal
            open={openUploadModal}
            onClose={() => {
              setOpenUploadModal(false);
            }}
            secondaryAction={true}
            onClickSecondaryAction={() => {
              hiddenFileInput.current.value = null;
              setOpenUploadModal(false);
            }}
            onClickPrimaryAction={(event) => {
              setOpenUploadModal(false);
              changeHandler(hiddenFileInput.current.files[0]);
            }}
            primaryLabel="Upload"
            secondaryLabe="Cancel"
            modalTitle="Upload"
          >
            <div className="renameContent">
              <CustomInput
                label="File name"
                // value={newFileName}
                // required={false}
                onChange={(e) => setNewFileName(e.target.value)}
              />
            </div>
          </Modal>
          <CustomButton
            {...commonButtonStyles}
            iconName="Download"
            withLabel={false}
            onClick={readOnly ? handleClick : undefined}
            Input={true}
            handleInput={() => setOpenUploadModal(true)}
            inputRef={hiddenFileInput}
            borderRight={true}
            tooltip="Import"
            cursor={`url(${readOnly ? Pointer : NotAllowed}), auto`}
          />
          <CustomButton
            {...commonButtonStyles}
            iconName="export3"
            withLabel={false}
            onClick={() => saveFile(data, location, revisionSelected?.xmlAlias)}
            borderRight={true}
            iconColor={themeColors[themeMode].iconColor}
            color={themeColors[themeMode].iconColor}
            tooltip="Export"
            cursor={`url(${Pointer}), auto`}
          />
          <CustomButton
            {...commonButtonStyles}
            iconName="Save"
            withLabel={false}
            onClick={handleSaveAndUpdate}
            borderRight={true}
            iconColor={
              readOnly
                ? themeColors[themeMode].TbButtonLabelDisable
                : themeColors[themeMode].iconColor
            }
            color={
              readOnly
                ? themeColors[themeMode].TbButtonLabelDisable
                : themeColors[themeMode].iconColor
            }
            tooltip="Save"
            cursor={`url(${!readOnly ? Pointer : NotAllowed}), auto`}
          />
        </div>
        <div className="middleButtons">
          <div className="revision-selected">
            <div className="revision-type">
              <Icon icon={"edit2"} size={12} />
              <div className="revision-type-label">
                {revisionSelected?.typeFile === "draft"
                  ? "Draft : "
                  : "Published : "}
              </div>
            </div>
            <div className="revision-label">
              {revisionSelected?.xmlAlias
                ? `
                 ${revisionSelected?.xmlAlias}`
                : !readOnly
                ? "Untitled"
                : "--"}
            </div>
            <div className="revision-status">
              <div>Edit mode</div>
              <Switcher
                toggleSwitch={() =>
                  readOnly ? handleEditVersion() : setShowDiscardPopup(true)
                }
                isActive={!readOnly}
              />
            </div>
          </div>
        </div>
        <div
          className="rightButtons"
          style={{
            width: revision && handleTransform ? "calc(30% - 20rem)" : "30%",
          }}
        >
          {compareXML && compare && (
            <CustomButton
              {...commonButtonStyles}
              iconName="reset"
              withLabel={false}
              borderRight={true}
              reverseBtn={true}
              borderLeft={true}
              onClick={handleResetChanges}
              color={themeColors[themeMode].iconColor}
              iconSize={20}
              cursor={`url(${Pointer}), auto`}
            />
          )}
          <CustomButton
            {...getButtonStyles(
              readOnly,
              themeColors,
              themeMode,
              compareXML,
              true
            )}
            iconName="compare"
            iconClose={compareXML && "close1"}
            label="Compare files"
            onClick={() => setCompareXML(!compareXML)}
            borderLeft={true}
            cursor={`url(${Pointer}), auto`}
          />
        </div>
      </div>
      <Modal
        open={showRenamePopup}
        onClose={() => {
          setShowRenamePopup(false);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowRenamePopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowRenamePopup(false);
          handlePublish(
            transformForXml(data),
            newFileName,
            store,
            "Published",
            addToast,
            true
          );
        }}
        primaryLabel="Publish"
        secondaryLabe="Cancel"
        modalTitle="Publish"
      >
        <div className="renameContent">
          <CustomInput
            label="File name"
            value={newFileName}
            required={false}
            onChange={(e) => setNewFileName(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        open={showDiscardPopup}
        onClose={() => {
          setShowDiscardPopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowDiscardPopup(false);
          handleDiscard();
          store.dispatch({
            type: "editor/updateXMLState",
            payload: { XMLChanged: false },
          });
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowDiscardPopup(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Exit Edit Mode"
      >
        <div className="ModalContent">
          <div className="message">
            Are you sure you want to leave edit mode ?
          </div>
          <div className="message">Any unsaved changes will be lost!</div>
          {/* <div className="draftVersion">{`Draft of: ${version} - ${xmlAlias}`}</div> */}
        </div>
      </Modal>
      <CustomModal
        open={showEditPopup}
        onClose={() => {
          setShowEditPopup(false);
        }}
        onClickPrimaryAction={() => {
          handlePublish(
            transformForXml(dataVersion),
            newFileName,
            store,
            "draft",
            addToast,
            false
          );
          setShowEditPopup(false);
        }}
        primaryLabel="Edit"
        modalTitle="Edit"
      >
        <div className="renameContent">
          <div className="message">
            Choose a name for the draft File of "File Name"
          </div>
          <TextField
            variant="outlined"
            label="File Name"
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              style: {
                borderRadius: "2px",
                alignItems: "center",
                fontFamily: "Hanken Grotesk, sans-serif",
                fontSize: "16px",
              },
            }}
          />
        </div>
      </CustomModal>
    </React.Fragment>
  );
}

export default AppBar;
