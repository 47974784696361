import xml2js from "minexml2js";
import transformForStore from "../../../services/helpers/transformForStore";
import updateVersion from "../../../services/updateVersion";

async function JsonToXML(jsonData = {}) {
  const builder = new xml2js.Builder();
  const xml = builder.buildObject(jsonData);
  const finalXML = xml.replace(/&amp;/g, "&");
  return finalXML;
}

async function XMLtoJson(XML = "") {
  try {
    const searchRegExp = new RegExp("&", "g");
    const xmlData = XML?.replace(searchRegExp, "&amp;");
    const { maxOrder, result } = await new xml2js.Parser({
      explicitArray: false,
      emptyTag: { $: { id: "mockId" } },
    }).parseStringPromise(xmlData);
    const skeleton = await transformForStore(result);

    return { skeleton, maxOrder };
  } catch (error) {
    console.error("Error parsing XML to JSON:", error);
    throw error;
  }
}
async function handleUpdate(fileName, jsonData, store) {
  try {
    const finalXML = await JsonToXML(jsonData);
    let { skeleton, maxOrder } = await XMLtoJson(finalXML);
    if (typeof skeleton === "string") {
      try {
        skeleton = JSON.parse(skeleton);
      } catch (parseError) {
        console.error("Failed to parse skeleton:", parseError);
      }
    }
    console.log("jsonData", skeleton);
    await updateVersion(fileName, store, skeleton, maxOrder);
  } catch (error) {
    console.log("error====", error);
    throw error;
  }
}

export default handleUpdate;
