function getResponseOfrequest(requestId, responsesArray) {
  console.log(requestId, 'requestId');
  console.log(responsesArray, 'responsesArray');
  let response = responsesArray.find((response) => {
    console.log(response, 'response');
    if (
      !response?.responseMetadata ||
      response?.responseMetadata?.requestId === undefined
    ) {
      return undefined;
    }
    return response?.responseMetadata?.requestId === requestId;
  });

  return response;
}

export default getResponseOfrequest;
