import React, { useEffect, useState } from "react";
import "./index.scss";

import Lottie from "react-lottie";
import {
  LoadingOptions,
  errorMessage,
  promises,
  succesMessage,
} from "../../../data";

import { ToggleButton } from "../../../../../components/button";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import DynamicModal from "../../../../../../../components/CustomModal";

import { __getScripts } from "../../services";
import {
  retryInstallAll,
  retryInstallOne,
} from "../../../SetupComponent/services";

function SyncObjectStructures() {
  const [isExpandedObjStructures, setIsExpandedObjStructures] = useState(false);
  const [scriptList, setScriptList] = useState({});
  const [isVerifying, setIsVerifying] = useState(false);
  const [enableRetryButton, setEnableRetryButton] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [loadingScripts, setLoadingScripts] = useState({});

  useEffect(() => {
    async function getScripts() {
      const response = (await __getScripts()) || {};
      let ObjectStructures = response?.ObjectStructures || {};
      const fetchedScripts = Object?.keys(ObjectStructures)?.map(
        (key) => ObjectStructures[key]
      );
      const matchedScripts = fetchedScripts?.map((script) => {
        const matchedPromise = promises?.find(
          (p) => p?.scriptName === script?.scriptName
        );
        return {
          ...script,
          scriptInstall: matchedPromise ? matchedPromise?.scriptInstall : null,
        };
      });
      setScriptList(matchedScripts);
    }
    getScripts();
  }, []);

  const openModal = (message) => {
    setCurrentMessage(message);
    setShowPopUp(true);
  };
  const closePopUp = () => {
    setShowPopUp(false);
  };

  const toggleState = (setState, currentState) => {
    setState(!currentState);
  };
  const scripts = [
    "Docklink OS",
    "Docklink OS",
    "MAXAPPSMOBILEApplication",
    "Maxmessages",
  ];

  return (
    <div className="SyncObjStructure">
      <div className="SyncObjStructureHeader">
        <div className="SyncObjStructureTitle">Object Structures</div>
        <ToggleButton
          isExpanded={isExpandedObjStructures}
          toggleFunction={() =>
            toggleState(setIsExpandedObjStructures, isExpandedObjStructures)
          }
          text={isExpandedObjStructures ? "Collapse" : "Expand"}
          icon={isExpandedObjStructures ? "collapse-2" : "descending"}
        />
      </div>
      {isExpandedObjStructures && (
        <div className="SyncObjStructureBody">
          {scriptList?.length > 0 ? (
            <div className="setupBody">
              <div className="setupTitles">
                {scriptList.map((elm, index) => (
                  <div className="setup-result" key={index}>
                    <div className="setup-messages">
                      <span className="setup-title">
                        {elm?.scriptName || scripts[index % scripts.length]} :
                      </span>

                      {loadingScripts?.[elm?.scriptName] ? (
                        <div className="errorMessage">
                          <Lottie
                            options={LoadingOptions}
                            height={20}
                            width={60}
                            isClickToPauseDisabled={true}
                          />
                        </div>
                      ) : elm?.success === true &&
                        elm?.content?.status === "success" ? (
                        <>
                          <span className="successMessage">
                            {succesMessage}
                          </span>
                          <div className="setup-icon">
                            <Icon
                              icon="check"
                              size={20}
                              style={{
                                color: "var(--Green)",
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <span className="errorMessage">{errorMessage}</span>
                          <div className="setup-icon">
                            <Icon
                              icon="info1"
                              size={20}
                              style={{
                                color: "#aaaaaa",
                                marginRight: "10px",
                              }}
                              onClick={() =>
                                openModal(elm?.content?.message || elm?.message)
                              }
                            />
                            <Icon
                              icon="Reboot"
                              size={24}
                              color="var(--Red)"
                              onClick={() => {
                                const scriptName = elm.scriptName;
                                setLoadingScripts((prev) => ({
                                  ...prev,
                                  [scriptName]: true,
                                }));
                                retryInstallOne(
                                  elm,
                                  scriptList,
                                  setIsVerifying,
                                  setEnableRetryButton,
                                  (updatedScripts) => {
                                    setScriptList(updatedScripts);
                                    setLoadingScripts((prev) => ({
                                      ...prev,
                                      [scriptName]: false,
                                    }));
                                  }
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="setupFooter">
                <button
                  className="setup-next-button"
                  onClick={() => {
                    setScriptList([]);
                    retryInstallAll(
                      scriptList,
                      setIsVerifying,
                      setEnableRetryButton,
                      setScriptList
                    );
                  }}
                >
                  Reinstall
                  <Icon
                    icon="Reboot"
                    size={20}
                    style={{ marginLeft: "10px" }}
                  />
                </button>
              </div>
            </div>
          ) : (
            <Lottie
              options={LoadingOptions}
              height={50}
              width={60}
              isClickToPauseDisabled={true}
              style={{
                cursor: "auto",
              }}
            />
          )}
        </div>
      )}
      <DynamicModal
        open={showPopUp}
        onClose={closePopUp}
        modalTitle={"Error"}
        iconTitle={
          <Icon icon="close1" size={27} style={{ color: "#DC2626" }} />
        }
        showFooter={false}
      >
        <div className="popupContainer-script">
          <div className="title-popup-script">{currentMessage}</div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default SyncObjectStructures;
