import "./index.scss"
import React, { useState, useEffect } from "react"
import CustomButton from "../../../../../components/CustomButton/index"
import changeImxLoggerAppName from "./services/changeImxLoggerAppName"
import useToasts from "../../../../../components/CustomToast/useToasts"
import useButtonTheme from "../../../customHooks/useButtonTheme"
import makeLoggerRequest from "../services/makeLoggerRequest"

const ServerControlPanel = ({
  envConfData = "",
  setEnvConfData = () => {},
}) => {
  const { addToast } = useToasts()
  useToasts()

  const [isChangeNameDisabled, setIsChangeNameDisabled] = useState(false)
  const [serverName, setServerName] = useState(
    envConfData.MX_LOGGER_APP_NAME || ""
  )
  const [connected, setConnected] = useState(false)
  const [lastButtonClicked, setLastButtonClicked] = useState(null)
  const LoggerChangeName = useButtonTheme("LoggerChangeName")
  const LoggerConnect = useButtonTheme("LoggerConnect", connected)
  const LoggerDisconnect = useButtonTheme("LoggerDisconnect")

  useEffect(() => {
    if (envConfData) {
      setServerName(envConfData.MX_LOGGER_APP_NAME)
    }
  }, [envConfData])

  const handleNameChange = (event) => {
    setServerName(event.target.value)
    if (isChangeNameDisabled) {
      setIsChangeNameDisabled(false)
    }
  }

  const handleChangeNameSubmit = async (event) => {
    event.preventDefault()
    const updatedEnvConfData = {
      ...envConfData,
      MX_LOGGER_APP_NAME: serverName,
    }

    try {
      const res = await changeImxLoggerAppName({ appName: serverName })
      console.log("Update response:", res)

      setEnvConfData(updatedEnvConfData)

      addToast(res?.content?.result, "success")

      setIsChangeNameDisabled(true)
    } catch (error) {
      console.error("error ===>", error)
      addToast("Failed to update server name", "error")
    }
  }

  const handleConnectToImxLogger = React.useCallback(async () => {
    if (lastButtonClicked !== "connect") {
      setLastButtonClicked("connect")
      try {
        setConnected(true)
        const connectResponse = await makeLoggerRequest(
          "NODE_ENDPOINT_CONNECT_IMXLOGGER"
        )

        addToast(connectResponse?.content?.result, "success")
      } catch (error) {
        console.error("error ===>", error)
      }
    }
  }, [lastButtonClicked])

  const handleDisconnectToImxLogger = React.useCallback(async () => {
    if (lastButtonClicked !== "disconnect") {
      setLastButtonClicked("disconnect")
      try {
        setConnected(false)
        const disconnectResponse = await makeLoggerRequest(
          "NODE_ENDPOINT_DISCONNECT_IMXLOGGER"
        )
        addToast(disconnectResponse?.content?.result, "success")
      } catch (error) {
        console.error("error ===>", error)
      }
    }
  }, [lastButtonClicked])

  const buttons = [
    {
      label: "Connect to IMXLOGGER",
      isActive: connected,
      onClick: handleConnectToImxLogger,
      theme: LoggerConnect,
    },
    {
      label: "Disconnect from IMXLOGGER",
      isActive: !connected,
      onClick: handleDisconnectToImxLogger,
      theme: LoggerDisconnect,
    },
  ]

  return (
    <div className='server-control-panel'>
      <div className='server-name-container'>
        <div className='ServerName'>Server Name</div>
        <form className='server-name-form' onSubmit={handleChangeNameSubmit}>
          <input
            className='server-name-input'
            type='text'
            value={serverName}
            onChange={handleNameChange}
          />

          <CustomButton
            label={"Change Name"}
            disabled={
              isChangeNameDisabled ||
              envConfData.MX_LOGGER_APP_NAME === serverName
            }
            {...LoggerChangeName}
          />
        </form>
      </div>

      <div className='connect-disconnect-container'>
        {buttons.map((button, index) => (
          <CustomButton
            key={index}
            label={button.label}
            isActive={button.isActive}
            onClick={button.onClick}
            {...button.theme}
          />
        ))}
      </div>
    </div>
  )
}

export default ServerControlPanel
