import getScripts from "../../../../services/installScripts/modules/getScripts";
import oneScriptItallation from "../../../../services/installScripts/modules/oneScriptItallation";

async function __installAll(setProgress, setScriptsList, setIsCompleted) {
  try {
    let files = await getScripts();
    let remoteScripts =
      files?.content?.status?.toUpperCase() === "SUCCESS"
        ? files?.content?.result
        : [];

    const scriptsArray = files?.content?.result || [];
    const IMXAPPS__CONFIGLOGGER__file =
      scriptsArray.filter((file) =>
        /(IMXAPPS__CONFIGLOGGER)\.py$/i.test(file)
      )?.[0] || "";
    const IMXAPPS__CONFIGIMXLOGGER__file =
      scriptsArray.filter((file) =>
        /(IMXAPPS__CONFIGIMXLOGGER)\.py$/i.test(file)
      )?.[0] || "";

    const filtredScript = remoteScripts.filter(
      (script) =>
        script !== IMXAPPS__CONFIGLOGGER__file &&
        script !== IMXAPPS__CONFIGIMXLOGGER__file
    );
    let allInstalledScript = [];
    const result = await Promise.allSettled(
      [IMXAPPS__CONFIGLOGGER__file, IMXAPPS__CONFIGIMXLOGGER__file].map(
        async (script) => {
          const scriptResult = await oneScriptItallation(script);
          allInstalledScript = [...allInstalledScript, scriptResult];
          const percentage = Math.round(
            (allInstalledScript.filter((item) => item.status !== "failure")
              .length /
              remoteScripts.length) *
              100
          );
          setProgress(percentage);
          setScriptsList(allInstalledScript);
          return true;
        }
      )
    );

    if (result) {
      await Promise.allSettled(
        filtredScript.map(async (script) => {
          const scriptResult = await oneScriptItallation(script);
          allInstalledScript = [...allInstalledScript, scriptResult];
          const percentage = Math.round(
            (allInstalledScript.filter((item) => item.status !== "failure")
              .length /
              remoteScripts.length) *
              100
          );
          setProgress(percentage);
          setScriptsList(allInstalledScript);
        })
      );
    }

    setIsCompleted(true);
  } catch (error) {
    console.log(error.message);
  }
}

// async function __installAll(setProgress, setScriptsList, setIsCompleted) {
//   try {
//     let files = await getScripts();
//     let remoteScripts =
//       files?.content?.status?.toUpperCase() === "SUCCESS"
//         ? files?.content?.result
//         : [];

//     let allInstalledScript = [];
//     let installAttemptCount = 0;
//     const scriptsToInstall = remoteScripts.filter(
//       (script) =>
//         script !== "IMXAPPS__CONFIGLOGGER.py" &&
//         script !== "IMXAPPS__CONFIGIMXLOGGER.py"
//     );

//     if (installAttemptCount < 43) {
//       const result = await Promise.allSettled(
//         scriptsToInstall.map(async (script) => {
//           if (installAttemptCount >= 43) return;
//           const scriptResult = await oneScriptItallation(script);
//           allInstalledScript.push(scriptResult);
//           installAttemptCount++;
//           const percentage = Math.round(
//             (allInstalledScript.filter((item) => item.status !== "failure")
//               .length /
//               remoteScripts.length) *
//               50
//           );
//           setProgress(percentage);
//           setScriptsList([...allInstalledScript]);
//         })
//       );

//       setIsCompleted(
//         installAttemptCount >= 43 ||
//           allInstalledScript.length === remoteScripts.length
//       );
//     } else {
//       console.log("Installation attempt limit reached.");
//       setIsCompleted(true);
//     }
//   } catch (error) {
//     console.error("Error=", error);
//   }
// }
export default __installAll;
