import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../../services/http/httpService";

async function updateVersion(filename, store, skeleton, maxOrder) {
  try {
    console.log("UpdateVersion", skeleton);
    const transactionNodeService = services.NODE_ENDPOINT_UPDATE_FILE_CONTENT;
    const transactionPayload = {
      filename,
      content: skeleton,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );
    return res?.data?.content;
  } catch (error) {
    // addToast("Server is unreachable!", "error");
    console.log("error====", error);

    return null;
  }
}

export default updateVersion;
