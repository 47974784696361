import React, { useEffect, useRef, useState } from "react";
import Body from "../Body";
import "./index.scss";

const BodyList = ({
  data = {},
  listData = {},
  path = [],
  currentPath = [],
  handleOpenActions = () => {},
  readOnly = true,
  relationship = false,
  setOpenRelationship,
}) => {
  const [previewImage, setPreviewImage] = useState(false);
  const [bodyCount, setBodyCount] = useState(1);
  const randomBodyCountRef = useRef(null);

  useEffect(() => {
    randomBodyCountRef.current = relationship
      ? 2
      : Math.floor(Math.random() * (20 - 5 + 1)) + 5;
    if (readOnly) {
      setBodyCount(randomBodyCountRef.current);
      if (
        listData?.hasOwnProperty("previewImage") &&
        listData?.previewImage === "true"
      ) {
        setPreviewImage(true);
      } else {
        setPreviewImage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);
  // useEffect(() => {
  //   if (!relationship) {
  //     addToast("Add relationShip to the list", "warning");
  //     console.log("GG");
  //   }
  // }, []);

  const renderBodies = () => {
    const bodies = [];
    for (let i = 0; i < bodyCount; i++) {
      bodies.push(
        <React.Fragment key={i}>
          <Body
            key={i}
            parentData={relationship ? data : data?.list}
            data={relationship ? data?.body : data?.list?.body}
            listData={listData}
            previewImage={previewImage}
            path={path}
            handleOpenActions={handleOpenActions}
            readOnly={readOnly}
            currentPath={currentPath}
            relationship={relationship}
            setOpenRelationship={setOpenRelationship}
          />
        </React.Fragment>
      );
    }
    return bodies;
  };

  return (
    <>
      {relationship ? (
        data?.body ? (
          <div className="bodies">{renderBodies()}</div>
        ) : (
          <div className="ListBody"></div>
        )
      ) : data?.list?.body ? (
        <div className="bodies">{renderBodies()}</div>
      ) : (
        <div className="ListBody">
          <div className="bbb">List </div>
        </div>
      )}
    </>
  );
};

export default BodyList;
