import React, { useEffect, useState } from "react";
import "./index.scss";
import SearchBar from "../../../../../components/searchBar";
import { ToggleButton } from "../../../../../components/button";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import Lottie from "react-lottie";
import { LoadingOptions } from "../../../data";
import ScriptListItem from "../../../../../components/scriptListItem";
import {
  __getScripts,
  calculatePercentages,
  calculateScriptCounts,
  filterScripts,
} from "../../services";

function SyncAutomationScripts() {
  const [searchInput, setSearchInput] = useState("");
  const [isExpandedAutoScripts, setIsExpandedAutoScripts] = useState(false);
  const [maximoConfig, setMaximoConfig] = useState(null);
  const [filteredScripts, setFilteredScripts] = useState([]);
  const [searchError, setSearchError] = useState(false);

  useEffect(() => {
    async function getScripts() {
      const response = await __getScripts();
      setMaximoConfig(response);
    }
    getScripts();
  }, []);

  useEffect(() => {
    setFilteredScripts(filterScripts(maximoConfig?.scripts, searchInput));
  }, [searchInput, maximoConfig]);

  useEffect(() => {
    setSearchError(filteredScripts?.length === 0);
  }, [filteredScripts]);

  const toggleState = (setState, currentState) => {
    setState(!currentState);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const { installedPercentage, failedPercentage } = calculatePercentages(
    maximoConfig?.scripts
  );
  const { warningScriptCount, errorScriptCount } = calculateScriptCounts(
    maximoConfig?.scripts
  );

  return (
    <div className="SyncAutomationScriptsContent">
      <div className="Automation-Scripts-Header">
        <SearchBar
          searchInput={searchInput}
          handleSearchChange={handleSearchChange}
        />
        <div className="AutomationScriptsList">
          <div className="Automation-Script-Expand">
            <div className="AutomationScriptsTitle">Automation Scripts</div>
            <ToggleButton
              isExpanded={isExpandedAutoScripts}
              toggleFunction={() =>
                toggleState(setIsExpandedAutoScripts, isExpandedAutoScripts)
              }
              text={isExpandedAutoScripts ? "Collapse" : "Expand"}
              icon={isExpandedAutoScripts ? "collapse-2" : "descending"}
            />
          </div>

          <div className="Automation-Script-Footer">
            <div className="Installation-Status">
              <div className="Installed-state">
                {installedPercentage}% Installed
              </div>
              <div className="Failed-state">
                {failedPercentage}% Failed to Install
              </div>
            </div>
            <div className="Installation-Status-icons">
              <div className="error-icon">
                {errorScriptCount}
                <Icon
                  icon="circle-close-new"
                  size={14}
                  style={{ color: "var(--Red)" }}
                />
              </div>
              <div className="warning-icon">
                {warningScriptCount}
                <Icon
                  icon="warning"
                  size={14}
                  style={{ color: "var(--Orange)" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExpandedAutoScripts && (
        <div className="Automation-Scripts-Body">
          {filteredScripts?.length > 0 ? (
            <div className="SyncSteps">
              {filteredScripts?.map((script, index) => (
                <ScriptListItem key={index} script={script} />
              ))}
            </div>
          ) : searchError ? (
            <div className="no-result-message">No result</div>
          ) : (
            <Lottie
              options={LoadingOptions}
              height={50}
              width={60}
              isClickToPauseDisabled={true}
              style={{
                cursor: "auto",
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SyncAutomationScripts;
