import "./index.scss"
import ImxLoggerSwitches from "./components/ImxLoggerSwitch/index"
import { useState, useEffect } from "react"

const ImxLoggerAction = ({
  label = "",
  backgroundColor,
  paddingTop = "0.625",
  paddingBottom = "0.625",
  paddingLeft = "0.625",
  paddingRight = "0.625",
  actionFunctions = {},
  borderRadius = 0,
  border = false,
  borderColor = "",
  borderBottomColor = "",
  envConfData = false,
  disableSwitch = false,
}) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const handleSwitchChange = (checked) => {
    setIsEnabled(checked)
    if (checked) {
      actionFunctions.enable && actionFunctions.enable()
      console.log(envConfData)
    } else {
      actionFunctions.disable && actionFunctions.disable()
    }
  }
  const style = {
    padding: `${paddingTop}rem ${paddingRight}rem ${paddingBottom}rem ${paddingLeft}rem`,
    backgroundColor: backgroundColor,
    borderRadius: `${borderRadius}px`,
    ...(border && borderColor && { border: `1px solid ${borderColor}` }),
    ...(borderBottomColor && {
      borderBottom: `1px solid ${borderBottomColor}`,
    }),
  }

  useEffect(() => {
    setIsEnabled(envConfData)
  }, [envConfData])

  return (
    <div className='ImxLoggerAction' style={style}>
      <p className='action-title'>{label}</p>
      <div className='toggle-button'>
        {(envConfData === true || envConfData === false) && (
          <ImxLoggerSwitches
            InitialSwitch={isEnabled}
            onChange={handleSwitchChange}
            disabled={disableSwitch}
          />
        )}
      </div>
    </div>
  )
}
export default ImxLoggerAction
