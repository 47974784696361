import React from "react";
import { useStore } from "react-redux";
import { handleComponentProp } from "../../services/handleComponentProperties";
import Message from "./Message";
import "./index.scss";
import { isSelectedComponent } from "../../services/isSelectedComponent";

const Notification = ({
  data = {},
  indexNotif,
  path = [],
  numNotif,
  currentPath = [],
  readOnly = true,
}) => {
  let store = useStore();
  return (
    <div
      className={`notification-badge-container ${
        isSelectedComponent(
          readOnly,
          numNotif === 1
            ? [...path, "notification"]
            : [...path, "notification", indexNotif?.toString()],
          currentPath
        ) && "selectedComponent"
      }`}
      onClick={(event) => {
        handleComponentProp(
          event,
          numNotif <= 1 ? null : indexNotif.toString(),
          data,
          path,
          store,
          "notification"
        );
        console.log("currentPath", currentPath, "path", path);
      }}
    >
      {data?.message ? (
        <Message
          data={data?.message}
          path={[
            ...path,
            ...(numNotif > 1
              ? ["notification", indexNotif.toString()]
              : ["notification"]),
          ]}
          currentPath={currentPath}
          readOnly={readOnly}
        />
      ) : (
        <div className="notif-no-message">Add Message</div>
      )}
    </div>
  );
};
export default Notification;
