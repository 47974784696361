import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import ReactJson from "react-json-view";
import { useTheme } from "../../../../../../theme/themeContext";
import Icon from "../../../../../../components/CustomIcon/Icon";
import { tableData, dataCollectionOptions, LoadingOptions } from "../../data";
import useDataCollection from "../../../../customHooks/useDataCollection";
import AdvancedSearch from "../AdvancedSearch/index";
import { CleaningDataCollection } from "../../services/cleanDataCollection";
import { cleanFetchCollection } from "../../services/cleanFetchCollection";
import { recursiveFn } from "../../services/recursiveFn";
import NoData from "../NoData/index";
import Lottie from "react-lottie";

function DataCollectionTable({
  toggleTable = "",
  toggleAdvancedSearch = "",
  setToggleAdvancedSearch = () => {},
  advancedSearchValue = {},
  setAdvancedSearchValue = () => {},
  advancedInputVisibility = {},
  setAdvancedInputVisibility = () => {},
  scrollPosition = "",
  updateScrollPosition = () => {},
  activeRow = "",
  setActiveRow = () => {},
  searchValue = "",
  setSearchValue = () => {},
  isSearching = "",
  setIsSearching = () => {},
  filteredData = [],
  setFilteredData = () => {},
  selectedColumns = [],
  handleCheckboxChange = () => {},
  onFailedDataClick = () => {},
}) {
  const { themeMode } = useTheme();
  const { data: finalDataCollection, loading } = useDataCollection(
    "NODE_ENDPOINT_GET_DATACOLLECTION",
    CleaningDataCollection
  );
  const { data: finalFailedDataCollection } = useDataCollection(
    "NODE_ENDPOINT_GET_FAILEDDATACOLLECTION",
    cleanFetchCollection
  );
  const tableRef = useRef(null);
  const rowScrollPosition = useRef(0);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const [failedDataCollectionId, setFailedDataCollectionId] = useState([]);
  const [toggleDropDown, setToggleDropdown] = useState(false);

  useEffect(() => {
    if (!isSearching) {
      setFilteredData(finalDataCollection);
    }
    const dataCollectionId = finalFailedDataCollection.map(
      (data) => data?.dataCollectionId
    );
    setFailedDataCollectionId(dataCollectionId);
  }, [finalDataCollection, finalFailedDataCollection, isSearching]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !dropdownButtonRef.current.contains(event.target)
      ) {
        setToggleDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = scrollPosition;
    }
  });

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = rowScrollPosition.current;
    }
  }, [activeRow]);

  const handleSearch = () => {
    if (searchValue.trim() === "") {
      setFilteredData(finalDataCollection);
      setIsSearching(false);
    } else {
      const newFilteredData = finalDataCollection.filter((item) =>
        recursiveFn(item, searchValue)
      );
      setFilteredData(newFilteredData);
      setIsSearching(true);
    }
    setActiveRow(null);
  };

  const handleRowClick = (index) => {
    const isRowActive = index === activeRow;
    if (!isRowActive) {
      rowScrollPosition.current = tableRef.current.scrollTop;
    }
    setActiveRow(isRowActive ? null : index);
  };

  const handleResetSearch = () => {
    setSearchValue("");
    setFilteredData(finalDataCollection);
    setIsSearching(false);
    setActiveRow(null);
  };

  const getCellContent = (row, column, rowIndex) => {
    if (column === "Collection ID") {
      const isFailed = failedDataCollectionId.includes(row?.dataCollectionId);
      return (
        <div className="collection-id-content">
          {isFailed && (
            <span
              className="failed-badge"
              onClick={() =>
                isFailed && onFailedDataClick(row?.dataCollectionId)
              }
            >
              failed
            </span>
          )}
          <span
            className={`data-collection-id ${isFailed ? "failed-cell" : ""}`}
            onClick={() => isFailed && onFailedDataClick(row?.dataCollectionId)}
          >
            {row?.dataCollectionId || "--"}
          </span>
        </div>
      );
    }

    switch (column) {
      case "#":
        return rowIndex + 1;
      case "Mboname":
        return row?.mboname || "--";
      case "WhereClause":
        return row?.whereclause || "--";
      case "Login":
        return row?.login || "--";
      case "Ready":
        return String(row?.ready) || "--";
      case "WhereClause ID":
        return row?.whereClauseId || "--";
      case "Result":
        return row?.result || "--";
      case "Data Hash":
        return row?.dataHash || "--";
      case "completewhereclause":
        return row?.completewhereclause || "--";
      case "mainWhereclause":
        return row?.mainWhereclause || "--";
      case "mainCompletewhereclause":
        return row?.mainCompletewhereclause || "--";
      default:
        return "--";
    }
  };

  return (
    <div>
      {loading ? (
        <Lottie
          options={LoadingOptions}
          height={100}
          width={120}
          isClickToPauseDisabled={true}
          style={{ cursor: "auto" }}
        />
      ) : (
        <div className="data-collection-container">
          {toggleAdvancedSearch[toggleTable]?.toggleFilter && (
            <AdvancedSearch
              setFilteredData={setFilteredData}
              data={finalDataCollection}
              toggleSearch={toggleTable}
              advancedSearchValue={advancedSearchValue}
              setAdvancedSearchValue={setAdvancedSearchValue}
              advancedInputVisibility={advancedInputVisibility}
              setAdvancedInputVisibility={setAdvancedInputVisibility}
              setActiveRow={setActiveRow}
            />
          )}
          <div className="monitor-Dashboard">
            <div className="search-table">
              <button
                className="advanced-filter"
                onClick={() => {
                  setToggleAdvancedSearch((prev) => ({
                    ...prev,
                    [toggleTable]: {
                      toggleFilter: !prev[toggleTable].toggleFilter,
                    },
                  }));
                }}
              >
                <Icon
                  icon="advanced-search"
                  size={22}
                  style={{
                    color: toggleAdvancedSearch[toggleTable]?.toggleFilter
                      ? "#4C36A5"
                      : "#989898",
                  }}
                />
              </button>
              <p className="search-divider"></p>
              <button className="default-search" onClick={handleSearch}>
                <Icon
                  icon={"search"}
                  size={22}
                  style={{ color: searchValue ? "#4C36A5" : "#989898" }}
                />
              </button>
              <input
                type="text"
                className="dashboard-search-input"
                placeholder={"Search"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <button className="reset-search" onClick={handleResetSearch}>
                <Icon
                  icon={isSearching && "circle-close-new"}
                  size={22}
                  style={{ color: "#C4C3C9" }}
                />
              </button>
              <p className="search-divider"></p>
              <button
                className="toggle-dropdown"
                ref={dropdownButtonRef}
                onClick={() => setToggleDropdown((prev) => !prev)}
              >
                <Icon
                  icon="dashboard-dropdown"
                  size={22}
                  style={{ color: toggleDropDown ? "#4C36A5" : "#989898" }}
                />
              </button>
              {toggleDropDown && (
                <div className="dropdown-content" ref={dropdownRef}>
                  {dataCollectionOptions.map((column, index) => (
                    <label className="dropdown-dashboard-label" key={index}>
                      <input
                        className="dropdown-input"
                        type="checkbox"
                        value={column}
                        checked={selectedColumns.includes(column)}
                        onChange={handleCheckboxChange}
                      />
                      {column}
                    </label>
                  ))}
                </div>
              )}
            </div>

            {finalDataCollection.length > 0 ? (
              <div className="data-table-wrapper" ref={tableRef}>
                <table className="data-table">
                  <thead className="table-header">
                    <tr>
                      {selectedColumns?.map((header, index) => (
                        <th
                          className={`header-cell ${
                            header === "#" ? "row-index-header" : ""
                          }`}
                          key={index}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tbody-wrapper">
                    {filteredData.length > 0 ? (
                      filteredData?.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          {activeRow === null || activeRow === rowIndex ? (
                            <>
                              <tr onClick={() => handleRowClick(rowIndex)}>
                                {selectedColumns.map((column, cellIndex) => {
                                  const cellClassName =
                                    column === "#" ? "row-index-cell" : "";
                                  return (
                                    <td
                                      key={cellIndex}
                                      className={cellClassName}
                                    >
                                      {getCellContent(row, column, rowIndex)}
                                    </td>
                                  );
                                })}
                              </tr>
                              {activeRow === rowIndex && (
                                <tr>
                                  <td
                                    colSpan={selectedColumns.length}
                                    className="full-width-row"
                                  >
                                    <ReactJson
                                      src={row}
                                      theme={
                                        themeMode === "light"
                                          ? "rjv-default"
                                          : "monokai"
                                      }
                                      collapsed={false}
                                      enableClipboard={true}
                                      displayObjectSize={true}
                                      displayDataTypes={true}
                                      name={false}
                                    />
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : null}
                        </React.Fragment>
                      ))
                    ) : (
                      <NoData
                        data1={
                          isSearching
                            ? "No results found."
                            : "No Data to Display."
                        }
                        data2={isSearching ? "Please try another search." : ""}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoData data1={"No data available."} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DataCollectionTable;

// {

// {
//   filteredData.length > 0 ? (
//     <div className={`grid-container ${activeRow ? "detail-shown" : ""}`}>
//       {filteredData
//         .slice((currentPage - 1) * 10, currentPage * 10)
//         .map((row, index) => {
//           const rowIndex = index + (currentPage - 1) * 10
//           if (activeRow !== null && activeRow !== rowIndex) {
//             return null
//           }

//           return (
//             <React.Fragment key={`row-${rowIndex}`}>
//               <div
//                 className={`row ${index % 2 === 0 ? "odd" : "even"} ${
//                   activeRow === rowIndex ? "active-row" : ""
//                 }`}
//                 onClick={() => handleRowClick(rowIndex)}
//               >
//                 {renderTruncatedCell(rowIndex + 1, "", true, rowIndex)}
//                 {renderTruncatedCell(row[0])}
//                 {renderTruncatedCell(row[1].mboname)}
//                 {renderTruncatedCell(row[1].login[0])}
//                 {renderTruncatedCell(row[1].whereClauseId)}
//                 {renderTruncatedCell(String(row[1].ready))}
//                 {renderTruncatedCell(row[1].result)}
//                 {renderTruncatedCell(row[1].dataHash)}
//                 {renderTruncatedCell(row[1].whereclause)}
//                 {/* {renderTruncatedCell(
//                 row.isProcessing?.toString(),
//                 "green-cell"
//               )} */}

//                 {/* {renderTruncatedCell(
//                 row.stopRefetch?.toString(),
//                 "red-cell"
//               )} */}
//               </div>
//               {activeRow === rowIndex && (
//                 <div className='full-width-row'>
//                   <ReactJson
//                     src={row}
//                     theme={
//                       themeMode === "light" ? "rjv-default" : "monokai"
//                     }
//                   />
//                 </div>
//               )}
//             </React.Fragment>
//           )
//         })}
//     </div>
//   ) : (
//     <div className='no-results-container'>
//       <p className='no-results-found-title '>No results found.</p>
//       <p className='no-results-found-title '>
//         Please try another search.
//       </p>
//     </div>
//   )
// }

// useEffect(() => {
//   const newFilteredData = finalDataCollection.filter((item) =>
//     Object.values(item || {}).some((value) =>
//       value?.toString().toLowerCase().includes(searchValue.toLowerCase())
//     )
//   )
//   setFilteredDataCollection(newFilteredData)
//   setTotalPages(Math.ceil(finalDataCollection.length / 10))
// }, [finalDataCollection, searchValue, setTotalPages])

// <div className='table-pagination'>
//   <PaginationButton
//     totalPages={totalPages}
//     currentPage={currentPage}
//     handlePageChange={handlePageChange}
//     setActiveRow={setActiveRow}
//   />
// </div>

// const [currentPage, setCurrentPage] = useState(1)

// const [totalPages, setTotalPages] = useState(0)
// const handlePageChange = (page) =>
//   setCurrentPage(typeof page === "number" ? page : null)
// setTotalPages(Math.ceil(finalDataCollection.length / 10))
