import React from "react";
import "./index.scss";
import DragPage from "../../../../../ComponentsLibrary/DragPage";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import { useStore } from "react-redux";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { useSelector } from "react-redux";

function EventToolbar({
  path = [],
  index = 0,
  data = {},
  numEvent = 0,
  events = [],
  setEvents = () => {},
  handleForm = () => {},
  readOnly = true,
  currentPath = [],
}) {
  let store = useStore();
  let skeletonState = useSelector(
    (state) => state?.entities?.explorer?.skeleton || {}
  );

  return (
    <DragPage
      index={index}
      className={`event-content ${
        isSelectedComponent(
          readOnly,
          numEvent <= 1
            ? [...path, "event"]
            : [...path, "event", index?.toString],
          currentPath
        ) && "selectedComponent"
      }`}
      component={"event"}
      dragParentPath={[...path, "event"]}
      dragData={data}
      dataAttributes={events}
      setDataAttributes={setEvents}
      onClick={(e) => {
        if(numEvent <=1 ){
          console.log("numEvent",numEvent);
        }
        console.log("skeleton",skeletonState)
        handleComponentProp(
          e,
          numEvent <= 1 ? null : index?.toString(),
          data,
          path,
          store,
          "event"
        );
      }}
      onDoubleClick={(e) => handleForm(e, data)}
    >
      <Icon
        size={20}
        icon={data?.service ? data?.service?.toLowerCase() : "missing"}
        color="white"
      />
    </DragPage>
  );
}

export default EventToolbar;
